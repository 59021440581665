import styled from 'styled-components';

export const LoginCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-height: 500px;
    min-width: 350px;

    > * {
        margin-bottom: 20px;
    }
`;

export const Title = styled.h2``;
