import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// TODO: Add react's lazy loading for code splitting
import { Dashboard } from 'pages/Dashboard';
import Login from 'pages/Login';
import { Users } from 'pages/Users';
import { Patient } from 'pages/Patient';
import { Trajectory } from 'pages/Trajectory';
import PasswordReset from 'pages/PasswordReset';
import PasswordResetRequest from 'pages/PasswordResetRequest';
import { selectUser } from 'slices/authenticationSlice';

const NotFound = lazy(() => import('pages/NotFound'));

const AppRoutes: React.FunctionComponent = () => {
    const user = useSelector(selectUser);

    return (
        <Routes>
            {user && (
                <>
                    {['admin', 'technicien', 'manager', 'collaborator'].includes(user.role) && (
                        <Route path="/tableau-de-bord" element={<Dashboard />} />
                    )}
                    {['admin', 'manager'].includes(user.role) && (
                        <Route path="/utilisateurs" element={<Users />} />
                    )}
                    {/* Separate routes for '/' and '/patients' both pointing to Patient element */}
                    {user.role === 'collaborator' && <Route path="/" element={<Patient />} />}
                    {['admin', 'technicien', 'manager'].includes(user.role) && (
                        <Route path="/patients" element={<Patient />} />
                    )}
                    {['admin', 'technicien', 'manager'].includes(user.role) && (
                        <Route path="/" element={<Trajectory />} />
                    )}
                    <Route element={<NotFound />} />
                </>
            )}
            {!user && (
                <>
                    <Route path={'/mot-de-passe/:token'} element={<PasswordReset />} />
                    <Route path={'/creation-compte/:token'} element={<PasswordReset />} />
                    <Route path="/mot-de-passe-oublie" element={<PasswordResetRequest />} />
                    <Route path="/" element={<Login />} />
                    <Route element={<NotFound />} />
                </>
            )}
        </Routes>
    );
};

export default AppRoutes;
