import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import type { RootState } from 'store';
import type {
    DashboardType,
    DashboardStatsType,
    TrajectoryGlobalStatsType,
    TrajectoryStepStatsType,
    DashboardCalculationType,
} from 'types';
import request from 'utils/request';

export type DashboardSliceType = {
    dashboard: DashboardType | null;
    trajectoryGlobalStats: TrajectoryGlobalStatsType | null;
    trajectoryStepsStats: TrajectoryStepStatsType[];
    error: string;
    dashboardCalculationInfos: DashboardCalculationType | null;
    isLoading: boolean;
};

export const initialState: DashboardSliceType = {
    dashboard: null,
    trajectoryGlobalStats: null,
    trajectoryStepsStats: [],
    error: '',
    dashboardCalculationInfos: null,
    isLoading: false,
};

// Requests

export const getDashboardInfoRequest = ({
    trajectoryId,
    status,
    begin,
    end,
}: {
    trajectoryId: number;
    status?: string;
    begin: string | null;
    end: string;
}) =>
    request<{ data: DashboardType }>(`/dashboard/trajectory/${trajectoryId}/info`, {
        method: 'GET',
        params: {
            status,
            begin,
            end,
        },
    });

export const getDashboardStatsV2Request = ({
    trajectoryId,
    status,
    begin,
    end,
}: {
    trajectoryId: number;
    status?: string;
    begin: string | null;
    end: string;
}) =>
    request<{ data: DashboardStatsType }>(`/dashboard/alpha/trajectory/${trajectoryId}/stats`, {
        method: 'GET',
        params: {
            status,
            begin,
            end,
        },
    });

export const execDashboardCalculationProcess = () =>
    request<{ data: string }>(`/dashboard/exec-calculation-process`, {
        method: 'POST',
    });

// Thunks

export const execDashboardCalculationProcessAsync = createAsyncThunk<{
    data: string;
}>('exec-calculation', async () => {
    const execDashboardCalculationResponse = await execDashboardCalculationProcess();
    return {
        data: execDashboardCalculationResponse.data.data,
    };
});

export const getDashboardInfoV2Async = createAsyncThunk<
    { dashboardInfoData: DashboardType; trajectoryStatsData: DashboardStatsType },
    { trajectoryId: number; status: string | undefined; begin: Date | null; end: Date }
>('dashboard-info-v2', async ({ trajectoryId, status, begin, end }) => {
    const beginString = begin === null ? null : dayjs(begin).format('YYYY-MM-DD');
    const endString = dayjs(end).format('YYYY-MM-DD');
    const dashboardInfoResponse = await getDashboardInfoRequest({
        trajectoryId,
        status,
        begin: beginString,
        end: endString,
    });
    const trajectoryStatsResponse = await getDashboardStatsV2Request({
        trajectoryId,
        status,
        begin: beginString,
        end: endString,
    });

    return {
        dashboardInfoData: dashboardInfoResponse.data.data,
        trajectoryStatsData: trajectoryStatsResponse.data.data,
    };
});

export const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanDashboard(state) {
            state.dashboard = null;
            state.error = '';
        },
        reset: () => initialState,
    },
    extraReducers(builder) {
        builder.addCase(getDashboardInfoV2Async.pending, (state) => {
            state.error = '';
            state.isLoading = true;
        });
        builder.addCase(getDashboardInfoV2Async.fulfilled, (state, { payload }) => {
            state.dashboard = payload.dashboardInfoData;
            state.trajectoryGlobalStats = payload.trajectoryStatsData.trajectoryGlobalStats;
            state.trajectoryStepsStats = payload.trajectoryStatsData.trajectoryStepsStats;
            state.error = '';
            state.isLoading = false;
        });
        builder.addCase(getDashboardInfoV2Async.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
            state.isLoading = false;
        });
        builder.addCase(execDashboardCalculationProcessAsync.pending, (state) => {
            state.error = '';
        });
        builder.addCase(execDashboardCalculationProcessAsync.fulfilled, (state) => {
            state.error = '';
        });
        builder.addCase(execDashboardCalculationProcessAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const { resetError, reset } = slice.actions;

export default slice.reducer;

// Selectors

export const selectDashboard = (state: RootState) => state.dashboard.dashboard;
export const selectTrajectoryStats = (state: RootState) => state.dashboard.trajectoryGlobalStats;
export const selectTrajectoryStatsSteps = (state: RootState) =>
    state.dashboard.trajectoryStepsStats;
export const selectIsLoading = (state: RootState) => state.dashboard.isLoading;
