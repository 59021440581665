import { DesignSystem } from '@osedea/reactor';

export const theme = {
    ...DesignSystem,
    colors: {
        ...DesignSystem.colors,
        white: '#FFFFFF',
        primary: {
            light: '#6bc2ea',
            main: '#0b4170',
            hover: '#28a6e1',
            focus: '#062642',
            dark: '#04192a',
            disabled: '#F2F2F2',
        },
    },
};
