import styled from 'styled-components';
import { UserPlus, UserMinus } from '@styled-icons/heroicons-outline';

export const UserAddIcon = styled(UserPlus)`
    padding: 8px;
    margin-left: 8px;
    cursor: pointer;
`;

export const UserRemoveIcon = styled(UserMinus)`
    cursor: pointer;
`;
