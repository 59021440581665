import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Edit,
    IconSuffix,
    Table,
    TextButton,
    TextField,
    TableExpandedContentToggler,
    TableExpandedContentContainer,
} from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import type { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import {
    getTrajectoryAsync,
    selectTrajectory,
    selectTotal,
    changeToAmount,
    selectPage,
    selectQuery,
    changeQuery,
    putTrajectoryAsync,
    postTrajectoryAsync,
    addTrajectory,
    removeTrajectory,
    selectTrajectoryHistory,
    getTrajectoryHistoryAsync,
    cleanTrajectoryHistory,
    selectTotalHistory,
    selectPageHistory,
    deleteTrajectoryAsync,
    changeToAmountHistory,
    createEventAsync,
    reset,
    selectPostSuccessful,
    resetPostSuccessful,
    selectPutSuccessful,
    resetPutSuccessful,
    selectError,
    resetError,
} from 'slices/trajectorySlice';

import {
    changeTrajectory,
    changeQuery as changeQueryStep,
    changeToAmount as changeToAmountStep,
} from 'slices/stepSlice';

import { selectUser } from 'slices/authenticationSlice';

import type { TrajectoryType, TrajectoryTypeHistory } from 'types';

import {
    TableRefiner,
    Pagination,
    Prev,
    Next,
    InputPagination,
    Title,
    QueryInput,
    QueryWrapper,
    ControlWrapper,
    SearchIcon,
    AddIcon,
    TimesCircleIcon,
    HistoryIcon,
    RemoveIcon,
} from 'styles/common';
import { StepTable } from 'components/StepTable';
import { action } from 'utils/type.utils';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const TrajectoryTable: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageSize = 10;

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [trajectoryOngoing, setTrajectoryOngoing] = React.useState({
        timeLimit: 0,
    } as TrajectoryType);

    const error = useSelector(selectError);
    const items = useSelector(selectTrajectory);
    const itemsHistory = useSelector(selectTrajectoryHistory);
    const query = useSelector(selectQuery);
    const [queryHistory, setQueryHistory] = useState(0);
    const [expandedRowId, setExpandedRowId] = useState(-1);
    const [orderBy, setOrderBy] = useState<string | number>('id');
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    const [orderByHistory, setOrderByHistory] = useState<string | number>('id');
    const [orderHistory, setOrderHistory] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    let page = useSelector(selectPage);
    let pageHistory = useSelector(selectPageHistory);
    const postSuccessful = useSelector(selectPostSuccessful);
    const putSuccessful = useSelector(selectPutSuccessful);
    const total = useSelector(selectTotal);
    const totalPage = Math.ceil(total / pageSize);
    const totalHistory = useSelector(selectTotalHistory);
    const user = useSelector(selectUser);
    const totalPageHistory = Math.ceil(totalHistory / pageSize);
    const [nameError, nameErrorSetter] = useState(false);
    // const [timeLimitError, timeLimitErrorSetter] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        dispatch(
            getTrajectoryAsync({ page, orderBy, order, query: query !== '' ? query : undefined }),
        );
        if (postSuccessful) {
            setActiveIndex(null);
            setOrderBy('id');
            setOrder('DESC');
            dispatch(resetPostSuccessful());
        }
        if (putSuccessful) {
            setActiveIndex(null);
            dispatch(resetPutSuccessful());
        }
    }, [dispatch, page, orderBy, order, postSuccessful, putSuccessful]);

    useEffect(() => {
        // hotfix to have a message when a step is deleted with data
        // Cf. https://github.com/Osedea/chum_parcours-patient-frontend/pull/228
        if (error) {
            alert(error);
        }
        dispatch(resetError());
    }, [error]);

    useEffect(
        () => () => {
            dispatch(reset());
        },
        [dispatch],
    );

    const getTrajectoryHistoryRequest = () => {
        dispatch(cleanTrajectoryHistory());
        dispatch(getTrajectoryHistoryAsync({ page: pageHistory, query: String(queryHistory) }));
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const headers = [
        {
            label: t('components.TrajectoryTable.headers.name'),
            id: 'name',
            sortable: true,
        },
        // {
        //     label: t('components.TrajectoryTable.headers.timeLimit'),
        //     id: 'timeLimit',
        // },
        {
            label: '',
            id: 'controls',
        },
    ];

    const headersHistory = [
        {
            label: t('components.TrajectoryTable.headers.name'),
            id: 'name',
        },
        // {
        //     label: t('components.TrajectoryTable.headers.timeLimit'),
        //     id: 'timeLimit',
        // },
        {
            label: t('components.Table.headers.lastModifiedBy'),
            id: 'lastModifiedBy',
        },
        {
            label: t('components.Table.headers.action'),
            id: 'action',
        },
        {
            label: t('components.Table.headers.lastModifiedDate'),
            id: 'lastModifiedDate',
            sortable: true,
        },
    ];

    const showHistoryHandler = (index: number) => {
        setQueryHistory(items[index].id);
        dispatch(cleanTrajectoryHistory());
        dispatch(getTrajectoryHistoryAsync({ page: pageHistory, query: String(items[index].id) }));
        openModal();
    };

    const removeTrajectoryHandler = (index: number) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce guichet?')) {
            dispatch(deleteTrajectoryAsync({ trajectory: items[index], index }));
        }
    };

    const setActiveIndexHandler = (index: number) => {
        setTrajectoryOngoing(items[index]);
        setActiveIndex(index);
    };

    const setExpandedRowIdHandler = (index: number) => {
        if (expandedRowId === index) {
            setExpandedRowId(-1);
            dispatch(changeTrajectory(-1));
        } else {
            setExpandedRowId(index);
            dispatch(changeQueryStep(String(items[index].id)));
            dispatch(changeTrajectory(items[index].id));
            dispatch(createEventAsync({ id: items[index].id }));
            dispatch(changeToAmountStep(1));
        }
    };

    const readOnlyRow = (item: TrajectoryType, index: number) => ({
        id: item.id,
        name: item.name,
        // timeLimit: item.timeLimit,
        controls: activeIndex ? (
            ''
        ) : (
            <>
                {user && user.role === 'admin' && (
                    <>
                        <TextButton onClick={() => setActiveIndexHandler(index)}>
                            {t('components.Table.edit')}
                            <IconSuffix icon={<Edit size="16" />} />
                        </TextButton>
                        <TextButton onClick={() => removeTrajectoryHandler(index)}>
                            {t('components.Table.remove')}
                            <IconSuffix icon={<RemoveIcon size="16" />} />
                        </TextButton>
                    </>
                )}
                <TextButton onClick={() => showHistoryHandler(index)}>
                    {t('components.Table.history')}
                    <IconSuffix icon={<HistoryIcon size="16" />} />
                </TextButton>
                <TableExpandedContentToggler
                    onClick={() => {
                        setExpandedRowIdHandler(index);
                    }}
                    label={t('components.Table.detail')}
                    expanded={expandedRowId === index}
                />
            </>
        ),
        expandedContent:
            expandedRowId === index ? (
                <TableExpandedContentContainer>
                    <StepTable />
                </TableExpandedContentContainer>
            ) : (
                <></>
            ),
    });

    const readOnlyHistoryRow = (item: TrajectoryTypeHistory, _index: number) => ({
        ...item,
        lastModifiedBy:
            item.lastModifiedBy === 'system'
                ? t(`components.Table.modifiedBySystem`)
                : item.lastModifiedBy,
        action: t(`components.Table.actions.${action(item)}`),
    });

    const nameValidator = (value: string): boolean => value === '';

    const nameHandler = (value: string, _index: number) => {
        const trajectory = {
            ...trajectoryOngoing,
            name: value,
        };
        nameErrorSetter(nameValidator(value));
        setTrajectoryOngoing(trajectory);
    };

    // const timeLimitValidator = (value: number): boolean => {
    //     return value <= 0;
    // };

    // const timeLimitHandler = (value: number, index: number) => {
    //     const trajectory = {
    //         ...trajectoryOngoing,
    //         timeLimit: value,
    //     };
    //     timeLimitErrorSetter(timeLimitValidator(value));
    //     setTrajectoryOngoing(trajectory);
    // };

    const cancelAddHandler = (index: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler l'ajout?")) {
            dispatch(removeTrajectory(index));
            setActiveIndex(null);
            dispatch(
                getTrajectoryAsync({
                    page,
                    orderBy,
                    order,
                    query: query !== '' ? query : undefined,
                }),
            );
        }
    };

    const cancelHandler = () => {
        if (window.confirm('Êtes-vous sûr de vouloir annuler la modification?')) {
            setActiveIndex(null);
        }
    };

    const validateTrajectory = () => {
        nameErrorSetter(nameValidator(trajectoryOngoing.name || ''));
        // timeLimitErrorSetter(timeLimitValidator(trajectoryOngoing.timeLimit || 0));
        return (
            // nameValidator(trajectoryOngoing.name) || timeLimitValidator(trajectoryOngoing.timeLimit)
            nameValidator(trajectoryOngoing.name)
        );
    };

    const putHandler = (index: number) => {
        if (validateTrajectory()) {
            return;
        }
        dispatch(putTrajectoryAsync({ trajectory: trajectoryOngoing, index }));
    };

    const postHandler = (index: number) => {
        if (validateTrajectory()) {
            return;
        }
        dispatch(postTrajectoryAsync({ trajectory: trajectoryOngoing, index }));
    };

    const editableRow = (item: TrajectoryType, index: number) => ({
        id: item.id,
        name: (
            <TextField
                label={t('components.TrajectoryTable.headers.name')}
                name="name"
                onChange={(e: React.FormEvent) =>
                    nameHandler((e.target as HTMLInputElement).value, index)
                }
                value={trajectoryOngoing.name || ''}
                hasError={nameError}
                assistiveText={nameError ? 'Obligatoire' : ''}
                required
            />
        ),
        // timeLimit: (
        //     <TextField
        //         label={t('components.TrajectoryTable.headers.timeLimit')}
        //         name="timeLimit"
        //         onChange={(e: React.FormEvent) =>
        //             timeLimitHandler(Number((e.target as HTMLInputElement).value), index)
        //         }
        //         value={trajectoryOngoing.timeLimit || ''}
        //         type="number"
        //         hasError={timeLimitError}
        //         assistiveText={timeLimitError ? 'Obligatoire' : ''}
        //         required
        //     />
        // ),
        controls:
            item.id === undefined ? (
                <>
                    <Button onClick={() => postHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelAddHandler(index)}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ) : (
                <>
                    <Button onClick={() => putHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelHandler()}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ),
    });

    const preparedRows = (trajectories: TrajectoryType[]) =>
        trajectories.map((item: TrajectoryType, index: number) => {
            if (index === activeIndex) {
                return editableRow(item, index);
            }

            return readOnlyRow(item, index);
        });

    const preparedHistoryRows = (trajectoryHistories: TrajectoryTypeHistory[]) =>
        trajectoryHistories.map((item: TrajectoryTypeHistory, index: number) =>
            readOnlyHistoryRow(item, index),
        );

    const prev = () => {
        if (page > 0) {
            page = page - 1;
            dispatch(changeToAmount(page));
        }
    };

    const next = () => {
        if (page < totalPage) {
            page = page + 1;
            dispatch(changeToAmount(page));
        }
    };

    const prevHistory = () => {
        if (pageHistory > 0) {
            pageHistory = pageHistory - 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getTrajectoryHistoryRequest();
    };

    const nextHistory = () => {
        if (pageHistory < totalPageHistory) {
            pageHistory = pageHistory + 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getTrajectoryHistoryRequest();
    };

    const pageNumberHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPage && newPageNumber > 0) {
            page = newPageNumber;
            dispatch(changeToAmount(page));
        }
    };

    const pageNumberHistoryHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPageHistory && newPageNumber > 0) {
            pageHistory = newPageNumber;
        }
        dispatch(changeToAmountHistory(newPageNumber));
        getTrajectoryHistoryRequest();
    };

    const queryHandler = (value: string) => {
        dispatch(changeQuery(value));
    };

    const keyPress = (value: number) => {
        if (value === 13 && query !== '') {
            page = 1;
            dispatch(
                getTrajectoryAsync({
                    page,
                    orderBy,
                    order,
                    query: query !== '' ? query : undefined,
                }),
            );
        }
    };

    const searchQueryHandler = () => {
        if (query !== '') {
            page = 1;
            dispatch(
                getTrajectoryAsync({
                    page,
                    orderBy,
                    order,
                    query: query !== '' ? query : undefined,
                }),
            );
        }
    };

    const addMoreHandler = () => {
        dispatch(addTrajectory());
        setTrajectoryOngoing({ timeLimit: 0 } as TrajectoryType);
        setActiveIndex(0);
    };

    const cleanQueryHandler = () => {
        dispatch(changeQuery(''));
        page = 1;
        dispatch(changeToAmount(page));
        dispatch(
            getTrajectoryAsync({
                page,
                orderBy,
                order,
            }),
        );
    };

    const orderHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderBy(by);
        setOrder(sortOrder);
        page = 1;
        dispatch(changeToAmount(page));
    };

    const orderHistoryHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderByHistory(by);
        setOrderHistory(sortOrder);
        pageHistory = 0;
        dispatch(changeToAmount(pageHistory));
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                <Title>{t('pages.Trajectory.history.title')}</Title>
                <TableRefiner>
                    <Table
                        headers={headersHistory}
                        rows={preparedHistoryRows(itemsHistory)}
                        sortOptions={{ sortBy: orderByHistory, sortOrder: orderHistory }}
                        onSort={(currentSortOptions) => orderHistoryHandler(currentSortOptions)}
                    />
                    {totalHistory > 10 && (
                        <Pagination>
                            <Prev onClick={prevHistory}>prev</Prev>
                            <InputPagination
                                value={pageHistory}
                                onChange={(e: React.FormEvent) =>
                                    pageNumberHistoryHandler((e.target as HTMLInputElement).value)
                                }
                            />{' '}
                            / {totalPageHistory}
                            <Next onClick={nextHistory}>next</Next>
                        </Pagination>
                    )}
                </TableRefiner>
            </Modal>
            <Title>
                {t('pages.Trajectory.title')}
                <ControlWrapper>
                    <QueryWrapper>
                        <QueryInput
                            value={query}
                            onChange={(e: React.FormEvent) =>
                                queryHandler((e.target as HTMLInputElement).value)
                            }
                            onKeyDown={(e: React.KeyboardEvent) => keyPress(e.keyCode)}
                            placeholder="Investigation pulmonaire"
                        />
                        {query !== '' && (
                            <TimesCircleIcon
                                size="16"
                                onClick={cleanQueryHandler}
                                style={{ marginRight: '8px' }}
                            />
                        )}
                        <SearchIcon size="16" onClick={searchQueryHandler} />
                    </QueryWrapper>
                    {user && user.role === 'admin' && (
                        <AddIcon size="16" onClick={addMoreHandler} />
                    )}
                </ControlWrapper>
            </Title>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={preparedRows(items)}
                    sortOptions={{ sortBy: orderBy, sortOrder: order }}
                    onSort={(currentSortOptions) => orderHandler(currentSortOptions)}
                />
                {total > 10 && (
                    <Pagination>
                        {page > 1 && <Prev onClick={prev}>{t('components.Table.prev')}</Prev>}
                        <InputPagination
                            value={page}
                            type="number"
                            onChange={(e: React.FormEvent) =>
                                pageNumberHandler((e.target as HTMLInputElement).value)
                            }
                        />{' '}
                        / {totalPage}
                        {page < totalPage && (
                            <Next onClick={next}>{t('components.Table.next')}</Next>
                        )}
                    </Pagination>
                )}
            </TableRefiner>
        </>
    );
};
