import type { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { UserType, UserTypeHistory } from 'types';
import request from 'utils/request';

const pageSize = 10;

export type UsersSliceType = {
    users: UserType[];
    error: string;
    total: number;
    page: number;
    query: string;
    usersHistory: UserTypeHistory[];
    errorHistory: string;
    totalHistory: number;
    pageHistory: number;
    postSuccessful: boolean;
    putSuccessful: boolean;
};

export const initialState: UsersSliceType = {
    users: [],
    error: '',
    total: 0,
    page: 1,
    query: '',
    usersHistory: [],
    errorHistory: '',
    totalHistory: 0,
    pageHistory: 1,
    postSuccessful: false,
    putSuccessful: false,
};

// Requests

export const getUserRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: UserType[]; total: number }>(`/users`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const postUserRequest = (user: UserType) =>
    request<{ data: UserType }>(`/users`, {
        method: 'POST',
        data: user,
    });

export const putUserRequest = (user: UserType) =>
    request<{ data: UserType }>(`/users/${user.id}`, {
        method: 'PUT',
        data: user,
    });

export const getUserHistoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: UserTypeHistory[]; total: number }>(`/audit-users`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const deleteUserRequest = (user: UserType) =>
    request(`/users/${user.id}`, {
        method: 'DELETE',
    });

export const createEventRequest = (id: number) =>
    request(`/users/event`, {
        method: 'POST',
        data: {
            id,
        },
    });

// Thunks

export const getUsersAsync = createAsyncThunk<
    { data: UserType[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('users', async ({ page, query, orderBy, order }) => {
    const response = await getUserRequest(page, query, orderBy, order);
    return response.data;
});

export const postUserAsync = createAsyncThunk<
    { value: UserType; index: number },
    { user: UserType; index: number }
>('post/users', async ({ user, index }) => {
    const response = await postUserRequest(user);
    return { value: response.data.data, index };
});

export const putUserAsync = createAsyncThunk<
    { value: UserType; index: number },
    { user: UserType; index: number }
>('put/users', async ({ user, index }) => {
    const response = await putUserRequest(user);
    return { value: response.data.data, index };
});

export const deleteUserAsync = createAsyncThunk<
    { index: number },
    { user: UserType; index: number }
>('delete/users', async ({ user, index }) => {
    await deleteUserRequest(user);
    return { index };
});

export const getUserHistoryAsync = createAsyncThunk<
    { data: UserTypeHistory[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('usersHistory', async ({ page, query, orderBy, order }) => {
    const response = await getUserHistoryRequest(page, query, orderBy, order);
    return response.data;
});

// eslint-disable-next-line @typescript-eslint/ban-types -- to be confirmed
export const createEventAsync = createAsyncThunk<{}, { id: number }>(
    'createEventAsync',
    async ({ id }) => {
        await createEventRequest(id);
    },
);

export const logoutUsers = () => (dispatch: Dispatch) => {
    dispatch(cleanUsers());
    dispatch(cleanUsersHistory());
};

export const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanUsers(state) {
            state.users = [];
            state.error = '';
        },
        setUsers(state, action: PayloadAction<{ data: UserType[]; total: number }>) {
            state.users = action.payload.data;
            state.total = action.payload.total;
            state.error = '';
        },
        addUsers(state) {
            state.users = [{} as UserType];
        },
        removeUsers(state, action: PayloadAction<number>) {
            state.users = state.users.slice(0, action.payload);
        },
        changeToAmount(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        cleanUsersHistory(state) {
            state.usersHistory = [];
            state.error = '';
        },
        changeToAmountHistory(state, action: PayloadAction<number>) {
            state.pageHistory = action.payload;
        },
        changeQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
            state.page = 1;
        },
        reset: (_state) => initialState,
        resetPostSuccessful(state) {
            state.postSuccessful = !state.postSuccessful;
        },
        resetPutSuccessful(state) {
            state.putSuccessful = !state.putSuccessful;
        },
    },
    extraReducers(builder) {
        builder.addCase(getUsersAsync.fulfilled, (state, { payload }) => {
            state.users = payload.data;
            state.total = payload.total;
            state.error = '';
        });
        builder.addCase(getUsersAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(postUserAsync.fulfilled, (state, { payload }) => {
            state.users[payload.index] = payload.value;
            state.postSuccessful = true;
            state.error = '';
        });
        builder.addCase(postUserAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(putUserAsync.fulfilled, (state, { payload }) => {
            state.users[payload.index] = payload.value;
            state.putSuccessful = true;
            state.error = '';
        });
        builder.addCase(putUserAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(deleteUserAsync.fulfilled, (state, { payload }) => {
            state.users = state.users.filter((_item, index) => index !== payload.index);
            state.error = '';
        });
        builder.addCase(deleteUserAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(getUserHistoryAsync.fulfilled, (state, { payload }) => {
            state.usersHistory = payload.data;
            state.totalHistory = payload.total;
            state.error = '';
        });
        builder.addCase(getUserHistoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const {
    resetError,
    setUsers,
    changeToAmount,
    changeToAmountHistory,
    cleanUsersHistory,
    changeQuery,
    addUsers,
    removeUsers,
    cleanUsers,
    reset,
    resetPostSuccessful,
    resetPutSuccessful,
} = slice.actions;

export default slice.reducer;

// Selectors

export const selectUsers = (state: RootState) => state.users.users;
export const selectTotal = (state: RootState) => state.users.total;
export const selectError = (state: RootState) => state.users.error;
export const selectPage = (state: RootState) => state.users.page;
export const selectQuery = (state: RootState) => state.users.query;
export const selectUsersHistory = (state: RootState) => state.users.usersHistory;
export const selectTotalHistory = (state: RootState) => state.users.totalHistory;
export const selectPageHistory = (state: RootState) => state.users.pageHistory;
export const selectPostSuccessful = (state: RootState) => state.users.postSuccessful;
export const selectPutSuccessful = (state: RootState) => state.users.putSuccessful;
