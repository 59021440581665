import type { Dispatch } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';

import type { GetStateFn, RootState } from '../store';
import type { UserType } from '../types';
import request from '../utils/request';

export type AuthenticationSliceType = {
    user: UserType | null;
    error: string;
};

export const initialState: AuthenticationSliceType = {
    user: null,
    error: '',
};

export const slice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        authSuccess(state: AuthenticationSliceType, action) {
            state.user = action.payload as UserType;
            state.error = '';
        },
        authFail(state, action) {
            state.user = null;
            state.error = action.payload as string;
        },
        logout(state) {
            state.user = null;
            state.error = '';
        },
    },
});

export const { authSuccess, authFail, logout, resetError } = slice.actions;

export default slice.reducer;

// Selectors

export const selectUser = (state: RootState) => state.authentication.user;
export const selectError = (state: RootState) => state.authentication.error;

// Requests

export const authenticate = (email: string, password: string) =>
    request<{ data: { token: string; user: UserType } }>(`/auth/login`, {
        method: 'POST',
        data: {
            email,
            password,
        },
    });

export const logoutRequest = () =>
    request<{ data: { token: string } }>(`/auth/logout`, {
        method: 'POST',
    });

export const retrieveAuthenticatedUser = () => request(`/auth/whoami`);

// Thunks

export const whoami = async (dispatch: Dispatch, _getState: GetStateFn) => {
    try {
        const response = await retrieveAuthenticatedUser();
        dispatch(authSuccess(response.data.data));
    } catch (error) {
        const err = error as AxiosResponse;
        dispatch(authFail(err.data as string));
    }
};

export const authenticateUser = (username: string, password: string) => (dispatch: Dispatch) => {
    dispatch(resetError());
    authenticate(username, password)
        .then((response) => {
            const data = response.data.data;
            if (data.token && data.token.length) {
                localStorage.setItem('api_token', data.token);

                dispatch(authSuccess(data.user));

                return retrieveAuthenticatedUser();
            }
            throw Error('User token not available.');
        })
        .catch((error: AxiosResponse) => {
            dispatch(authFail(error.data as string));
        });
};

export const logoutAuthentication = () => (dispatch: Dispatch) => {
    logoutRequest().then(() => {
        localStorage.removeItem('api_token');
        dispatch(logout());
    });
};

export const sendResetPasswordEmail =
    (email: string) => async (dispatch: Dispatch, _getState: GetStateFn) => {
        try {
            await request('/users/send-password-reset', {
                method: 'POST',
                data: {
                    email,
                },
            });
        } catch (error) {
            const err = error as AxiosResponse;
            dispatch(authFail(err.data as string));
        }
    };

export const updatePassword =
    ({ token, password }: { token: string; password: string }) =>
    async (dispatch: Dispatch, _getState: GetStateFn) => {
        try {
            await request('/users/reset-password', {
                method: 'POST',
                data: {
                    token,
                    password,
                },
            });
        } catch (error) {
            const err = error as AxiosResponse;
            dispatch(authFail(err.data as string));
        }
    };
