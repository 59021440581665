import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Edit,
    IconSuffix,
    Table,
    TextButton,
    TextField,
    Select,
    TableExpandedContentToggler,
    TableExpandedContentContainer,
} from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import type { SelectOption } from '@osedea/reactor/dist/components/textFields/types';
import type { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import {
    getUsersAsync,
    selectUsers,
    selectTotal,
    changeToAmount,
    selectPage,
    selectQuery,
    changeQuery,
    putUserAsync,
    postUserAsync,
    addUsers,
    removeUsers,
    selectUsersHistory,
    getUserHistoryAsync,
    cleanUsersHistory,
    selectTotalHistory,
    selectPageHistory,
    deleteUserAsync,
    changeToAmountHistory,
    createEventAsync,
    reset,
    selectPostSuccessful,
    resetPostSuccessful,
    selectPutSuccessful,
    resetPutSuccessful,
} from 'slices/usersSlice';

import {
    changeUsers,
    changeQuery as changeQueryUsersTrajectory,
} from 'slices/usersTrajectorySlice';

import { selectUser } from 'slices/authenticationSlice';

import type { UserType, UserTypeHistory } from 'types';

import { UserAddIcon, UserRemoveIcon } from './styles';

import {
    TableRefiner,
    Pagination,
    Prev,
    Next,
    InputPagination,
    Title,
    QueryInput,
    QueryWrapper,
    ControlWrapper,
    SearchIcon,
    TimesCircleIcon,
    HistoryIcon,
} from 'styles/common';

import { UsersTrajectoryTable } from 'components/UsersTrajectoryTable';
import { action, userRole } from 'utils/type.utils';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const UsersTable: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageSize = 10;

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [userOngoing, setUserOngoing] = React.useState({} as UserType);

    const items = useSelector(selectUsers);
    const itemsHistory = useSelector(selectUsersHistory);
    const query = useSelector(selectQuery);
    const [queryHistory, setQueryHistory] = useState(0);
    const [expandedRowId, setExpandedRowId] = useState(-1);
    const [orderBy, setOrderBy] = useState<string | number>('id');
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    const [orderByHistory, setOrderByHistory] = useState<string | number>('id');
    const [orderHistory, setOrderHistory] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    let page = useSelector(selectPage);
    let pageHistory = useSelector(selectPageHistory);
    const postSuccessful = useSelector(selectPostSuccessful);
    const putSuccessful = useSelector(selectPutSuccessful);
    const total = useSelector(selectTotal);
    const totalPage = Math.ceil(total / pageSize);
    const totalHistory = useSelector(selectTotalHistory);
    const user = useSelector(selectUser);
    const totalPageHistory = Math.ceil(totalHistory / pageSize);
    const [emailError, emailErrorSetter] = useState(false);
    const [roleError, roleErrorSetter] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        dispatch(getUsersAsync({ page, orderBy, order, query: query !== '' ? query : undefined }));
        if (postSuccessful) {
            setActiveIndex(null);
            setOrderBy('id');
            setOrder('DESC');
            dispatch(resetPostSuccessful());
        }
        if (putSuccessful) {
            setActiveIndex(null);
            dispatch(resetPutSuccessful());
        }
    }, [dispatch, page, orderBy, order, postSuccessful, putSuccessful]);

    useEffect(
        () => () => {
            dispatch(reset());
        },
        [dispatch],
    );

    const getUserHistoryRequest = () => {
        dispatch(cleanUsersHistory());
        dispatch(
            getUserHistoryAsync({ page: pageHistory, query: String(queryHistory), orderBy, order }),
        );
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };
    const headers = [
        {
            label: t('components.UsersTable.headers.email'),
            id: 'email',
            sortable: true,
        },
        {
            label: t('components.UsersTable.headers.role'),
            id: 'role',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const headersHistory = [
        {
            label: t('components.UsersTable.headers.email'),
            id: 'email',
        },
        {
            label: t('components.UsersTable.headers.role'),
            id: 'role',
        },
        {
            label: t('components.Table.headers.lastModifiedBy'),
            id: 'lastModifiedBy',
        },
        {
            label: t('components.Table.headers.action'),
            id: 'action',
        },
        {
            label: t('components.Table.headers.lastModifiedDate'),
            id: 'lastModifiedDate',
            sortable: true,
        },
    ];

    const roles = [
        {
            label: t('entity.UserRole.selectRole'),
            value: '',
            disabled: true,
        },
        {
            label: t('entity.UserRole.admin'),
            value: 'admin',
        },
        {
            label: t('entity.UserRole.technicien'),
            value: 'technicien',
        },
        {
            label: t('entity.UserRole.manager'),
            value: 'manager',
        },
        {
            label: t('entity.UserRole.collaborator'),
            value: 'collaborator',
        },
    ] as SelectOption[];

    const showHistoryHandler = (index: number) => {
        setQueryHistory(items[index].id);
        dispatch(cleanUsersHistory());
        dispatch(
            getUserHistoryAsync({
                page: pageHistory,
                query: String(items[index].id),
                orderBy,
                order,
            }),
        );
        openModal();
    };

    const removeUserHandler = (index: number) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce compte?')) {
            dispatch(deleteUserAsync({ user: items[index], index }));
        }
    };

    const setActiveIndexHandler = (index: number) => {
        setUserOngoing(items[index]);
        setActiveIndex(index);
    };

    const setExpandedRowIdHandler = (index: number) => {
        if (expandedRowId === index) {
            setExpandedRowId(-1);
            dispatch(changeUsers(-1));
        } else {
            setExpandedRowId(index);
            dispatch(changeQueryUsersTrajectory(String(items[index].id)));
            dispatch(changeUsers(items[index].id));
            dispatch(createEventAsync({ id: items[index].id }));
        }
    };

    const readOnlyRow = (item: UserType, index: number) => ({
        ...item,
        role: t(`entity.UserRole.${userRole(item)}`),
        controls: activeIndex ? (
            ''
        ) : (
            <>
                {user && user.role === 'admin' && (
                    <>
                        <TextButton onClick={() => setActiveIndexHandler(index)}>
                            {t('components.Table.edit')}
                            <IconSuffix icon={<Edit size="16" />} />
                        </TextButton>
                        <TextButton onClick={() => removeUserHandler(index)}>
                            {t('components.Table.remove')}
                            <IconSuffix icon={<UserRemoveIcon size="16" />} />
                        </TextButton>
                    </>
                )}
                <TextButton onClick={() => showHistoryHandler(index)}>
                    {t('components.Table.history')}
                    <IconSuffix icon={<HistoryIcon size="16" />} />
                </TextButton>
                {items[index].role !== 'admin' && (
                    <TableExpandedContentToggler
                        onClick={() => {
                            setExpandedRowIdHandler(index);
                        }}
                        label={t('components.Table.detail')}
                        expanded={expandedRowId === index}
                    />
                )}
            </>
        ),
        expandedContent:
            expandedRowId === index ? (
                <TableExpandedContentContainer>
                    <UsersTrajectoryTable />
                </TableExpandedContentContainer>
            ) : (
                <></>
            ),
    });
    const readOnlyHistoryRow = (item: UserTypeHistory, _index: number) => ({
        ...item,
        lastModifiedBy:
            item.lastModifiedBy === 'system'
                ? t(`components.Table.modifiedBySystem`)
                : item.lastModifiedBy,
        action: t(`components.Table.actions.${action(item)}`),
    });

    const emailValidator = (value: string): boolean => value === '';

    const emailHandler = (value: string, _index: number) => {
        const ongoingUser = {
            ...userOngoing,
            email: value,
        };
        emailErrorSetter(emailValidator(value));
        setUserOngoing(ongoingUser);
    };

    const roleValidator = (value: string): boolean => value === '';

    const roleHandler = (value: string, _index: number) => {
        const ongoingUser = {
            ...userOngoing,
            role: value,
        };
        roleErrorSetter(roleValidator(value));
        setUserOngoing(ongoingUser);
    };

    const cancelAddHandler = (index: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler l'ajout?")) {
            dispatch(removeUsers(index));
            setActiveIndex(null);
            dispatch(
                getUsersAsync({ page, orderBy, order, query: query !== '' ? query : undefined }),
            );
        }
    };

    const cancelHandler = () => {
        if (window.confirm('Êtes-vous sûr de vouloir annuler la modification?')) {
            setActiveIndex(null);
        }
    };

    const validateUser = () => {
        emailErrorSetter(emailValidator(userOngoing.email || ''));
        roleErrorSetter(roleValidator(userOngoing.role || ''));
        return emailValidator(userOngoing.email) || roleValidator(userOngoing.role);
    };

    const putHandler = (index: number) => {
        if (validateUser()) {
            return;
        }
        const ongoingUser = {
            id: userOngoing.id,
            email: userOngoing.email,
            role: userOngoing.role,
        };
        dispatch(putUserAsync({ user: ongoingUser, index }));
    };

    const postHandler = (index: number) => {
        if (validateUser()) {
            return;
        }
        const ongoingUser = {
            id: userOngoing.id,
            email: userOngoing.email,
            role: userOngoing.role,
        };
        dispatch(postUserAsync({ user: ongoingUser, index }));
    };

    const editableRow = (item: UserType, index: number) => ({
        id: item.id,
        email: (
            <TextField
                label={t('components.UsersTable.headers.email')}
                name="email"
                onChange={(e: React.FormEvent) =>
                    emailHandler((e.target as HTMLInputElement).value, index)
                }
                value={userOngoing.email || ''}
                hasError={emailError}
                assistiveText={emailError ? 'Obligatoire' : ''}
                required
            />
        ),
        role: (
            <Select
                name="role"
                options={roles}
                label={t('components.UsersTable.headers.role')}
                value={userOngoing.role || ''}
                onChange={(e: React.FormEvent) =>
                    roleHandler((e.target as HTMLInputElement).value, index)
                }
                variant="outlined"
                hasError={roleError}
                assistiveText={roleError ? 'Obligatoire' : ''}
                required
            />
        ),
        controls:
            item.id === undefined ? (
                <>
                    <Button onClick={() => postHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelAddHandler(index)}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ) : (
                <>
                    <Button onClick={() => putHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelHandler()}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ),
    });

    const preparedRows = (users: UserType[]) =>
        users.map((item: UserType, index: number) => {
            if (index === activeIndex) {
                return editableRow(item, index);
            }

            return readOnlyRow(item, index);
        });

    const preparedHistoryRows = (userHistories: UserTypeHistory[]) =>
        userHistories.map((item: UserTypeHistory, index: number) =>
            readOnlyHistoryRow(item, index),
        );

    const prev = () => {
        if (page > 0) {
            page = page - 1;
            dispatch(changeToAmount(page));
        }
    };

    const next = () => {
        if (page < totalPage) {
            page = page + 1;
            dispatch(changeToAmount(page));
        }
    };

    const prevHistory = () => {
        if (pageHistory > 0) {
            pageHistory = pageHistory - 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getUserHistoryRequest();
    };

    const nextHistory = () => {
        if (pageHistory < totalPageHistory) {
            pageHistory = pageHistory + 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getUserHistoryRequest();
    };

    const pageNumberHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPage && newPageNumber > 0) {
            page = newPageNumber;
            dispatch(changeToAmount(page));
        }
    };

    const pageNumberHistoryHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPageHistory && newPageNumber > 0) {
            pageHistory = newPageNumber;
        }
        dispatch(changeToAmountHistory(newPageNumber));
        getUserHistoryRequest();
    };

    const queryHandler = (value: string) => {
        dispatch(changeQuery(value));
    };

    const keyPress = (value: number) => {
        if (value === 13 && query !== '') {
            page = 1;
            dispatch(
                getUsersAsync({ page, orderBy, order, query: query !== '' ? query : undefined }),
            );
        }
    };

    const searchQueryHandler = () => {
        if (query !== '') {
            page = 1;
            dispatch(
                getUsersAsync({ page, orderBy, order, query: query !== '' ? query : undefined }),
            );
        }
    };

    const addMoreHandler = () => {
        dispatch(addUsers());
        setUserOngoing({} as UserType);
        setActiveIndex(0);
    };

    const cleanQueryHandler = () => {
        dispatch(changeQuery(''));
        page = 1;
        dispatch(changeToAmount(page));
        dispatch(getUsersAsync({ page, orderBy, order }));
    };

    const orderHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderBy(by);
        setOrder(sortOrder);
        page = 1;
        dispatch(changeToAmount(page));
    };

    const orderHistoryHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderByHistory(by);
        setOrderHistory(sortOrder);
        pageHistory = 0;
        dispatch(changeToAmount(pageHistory));
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                <Title>{t('pages.Users.history.title')}</Title>
                <TableRefiner>
                    <Table
                        headers={headersHistory}
                        rows={preparedHistoryRows(itemsHistory)}
                        sortOptions={{ sortBy: orderByHistory, sortOrder: orderHistory }}
                        onSort={(currentSortOptions) => orderHistoryHandler(currentSortOptions)}
                    />
                    {totalHistory > 10 && (
                        <Pagination>
                            <Prev onClick={prevHistory}>{t('components.Table.prev')}</Prev>
                            <InputPagination
                                value={pageHistory}
                                onChange={(e: React.FormEvent) =>
                                    pageNumberHistoryHandler((e.target as HTMLInputElement).value)
                                }
                            />{' '}
                            / {totalPageHistory}
                            <Next onClick={nextHistory}>{t('components.Table.next')}</Next>
                        </Pagination>
                    )}
                </TableRefiner>
            </Modal>
            <Title>
                {t('pages.Users.title')}
                <ControlWrapper>
                    <QueryWrapper>
                        <QueryInput
                            value={query}
                            onChange={(e: React.FormEvent) =>
                                queryHandler((e.target as HTMLInputElement).value)
                            }
                            onKeyDown={(e: React.KeyboardEvent) => keyPress(e.keyCode)}
                            placeholder="test@mail.com"
                        />
                        {query !== '' && (
                            <TimesCircleIcon
                                size="16"
                                onClick={cleanQueryHandler}
                                style={{ marginRight: '8px' }}
                            />
                        )}
                        <SearchIcon size="16" onClick={searchQueryHandler} />
                    </QueryWrapper>
                    {user && user.role === 'admin' && (
                        <UserAddIcon size="16" onClick={addMoreHandler} />
                    )}
                </ControlWrapper>
            </Title>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={preparedRows(items)}
                    sortOptions={{ sortBy: orderBy, sortOrder: order }}
                    onSort={(currentSortOptions) => orderHandler(currentSortOptions)}
                />
                {total > 10 && (
                    <Pagination>
                        {page > 1 && <Prev onClick={prev}>{t('components.Table.prev')}</Prev>}
                        <InputPagination
                            value={page}
                            type="number"
                            onChange={(e: React.FormEvent) =>
                                pageNumberHandler((e.target as HTMLInputElement).value)
                            }
                        />{' '}
                        / {totalPage}
                        {page < totalPage && (
                            <Next onClick={next}>{t('components.Table.next')}</Next>
                        )}
                    </Pagination>
                )}
            </TableRefiner>
        </>
    );
};
