import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Edit, IconSuffix, TextButton, TextField } from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';

import { putPatientAsync } from 'slices/patientSlice';

import { ButtonsContainer } from 'styles/common';
import type { PatientType } from 'types';

import { USER_ROLES_HAVING_THE_RIGHT_TO_EDIT_PATIENTS } from 'constants/index';
import { selectUser } from 'slices/authenticationSlice';

export interface IProps {
    patient: PatientType;
    index: number;
}

export const PatientComment: React.FunctionComponent<IProps> = ({ patient, index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [patientObject, setPatientObject] = useState<PatientType>(patient);
    const [editComment, setEditComment] = useState<boolean>(false);

    const user = useSelector(selectUser);
    const userCanEditPatientComment =
        user && USER_ROLES_HAVING_THE_RIGHT_TO_EDIT_PATIENTS.includes(user.role);

    const cancelHandler = () => {
        if (window.confirm('Êtes-vous sûr de vouloir annuler la modification?')) {
            setEditComment(false);
        }
    };

    return (
        <>
            {!editComment && (
                <>
                    {patientObject.comment || t('pages.Patient.noComment')}
                    {userCanEditPatientComment && (
                        <TextButton onClick={() => setEditComment(true)}>
                            {t('components.Table.edit')}
                            <IconSuffix icon={<Edit size="16" />} />
                        </TextButton>
                    )}
                </>
            )}
            {editComment && (
                <>
                    <TextField
                        name="patientComment"
                        type="text"
                        label={t('pages.Patient.commentLabel')}
                        onChange={(e: React.FormEvent) =>
                            setPatientObject({
                                ...patientObject,
                                comment: (e.target as HTMLInputElement).value,
                            })
                        }
                        value={patientObject.comment}
                        multiline
                    />
                    <ButtonsContainer>
                        <Button
                            onClick={() => {
                                dispatch(putPatientAsync({ patient: patientObject, index }));
                                setEditComment(false);
                            }}
                        >
                            {t('components.Table.save')}
                        </Button>
                        <Button
                            style={{ marginLeft: '8px' }}
                            onClick={() => cancelHandler()}
                            variant="destructive"
                        >
                            {t('components.Table.cancel')}
                        </Button>
                    </ButtonsContainer>
                </>
            )}
        </>
    );
};
