import type {
    PatientType,
    PatientTypeHistory,
    StepType,
    TrajectoryTypeHistory,
    UserType,
    UserTypeHistory,
} from 'types';

/**
 * utils to help with types
 * @param history item
 * @returns the typed action
 */
export function action(
    history: TrajectoryTypeHistory | UserTypeHistory | PatientTypeHistory,
): 'created' | 'deleted' | 'read' | 'updated' {
    return history.action as 'created' | 'deleted' | 'read' | 'updated';
}

/**
 * utils to help with types
 * @param {UserType} user - user
 * @returns {"admin" | "technicien" | "manager" | "collaborator"}
 */
export function userRole(user: UserType) {
    return user.role as 'admin' | 'technicien' | 'manager' | 'collaborator';
}

/**
 * utils to help with types
 * @param step
 * @returns
 */
export function stepIndicator(step: StepType): 'selectIndicator' | 'start' | 'end' | 'none' {
    return step.indicator as 'selectIndicator' | 'start' | 'end' | 'none';
}

export function status(
    patient: Pick<PatientType, 'status'>,
): 'ongoing' | 'completed' | 'cancelled' | 'undefined' {
    return patient.status as 'ongoing' | 'completed' | 'cancelled' | 'undefined';
}
