import type { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosPromise } from 'axios';
import axios from 'axios';

/**
 * Requests a URL, returning a promise.
 *
 * Axios by default will reject a promise if it is not between status codes 200-300
 * (This can be modified by implementing the validateStatus method)
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {humps} [optional] Set to false to not camelize/decamelize keys
 *
 * @return {object}           An object containing either "data" or "err"
 */

type Method =
    | 'GET'
    | 'get'
    | 'delete'
    | 'DELETE'
    | 'head'
    | 'HEAD'
    | 'options'
    | 'OPTIONS'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH'
    | 'link'
    | 'LINK'
    | 'unlink'
    | 'UNLINK'
    | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- to be confirmed
const request = <T extends { data: any }>(
    url: string,

    options: { method?: Method; data?: object; params?: object } = {
        method: 'GET',
        data: {},
        params: {},
    },
): AxiosPromise<T> => {
    let Authorization = {};
    const API_TOKEN = localStorage.getItem('api_token');
    if (API_TOKEN) {
        Authorization = { Authorization: `Bearer ${API_TOKEN}` };
    }

    const config: AxiosRequestConfig = {
        url: `${process.env.REACT_APP_API_URL}${url}`,
        method: options.method ? options.method : 'GET',
        data: options.data ?? {},
        params: options.params ?? {},
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...Authorization,
        },
    };

    return axios(config)
        .then((response: AxiosResponse<T>) => Promise.resolve(response))
        .catch((error: AxiosError) => {
            // if the error is a 401 and not already at login, remove the token and redirect to the login page
            if (error.response?.status === 401 && window.location.pathname !== '/') {
                localStorage.removeItem('api_token');
                window.location.href = '/?errorKey=unauthorized';
            }
            // if the error is a 403, remove the token and redirect to the login page
            if (error.response?.status === 403) {
                localStorage.removeItem('api_token');
                window.location.href = '/?errorKey=forbidden';
            }
            return Promise.reject(error.response ?? {});
        });
};

export default request;
