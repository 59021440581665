import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSuffix, Table, TextButton } from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';

import type { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import {
    selectUsersTrajectory,
    selectTotal,
    changeToAmount,
    selectPage,
    postUsersTrajectoryAsync,
    deleteUsersTrajectoryAsync,
    selectUsersId,
    getAllUsersTrajectoryAsync,
} from 'slices/usersTrajectorySlice';

import { selectTrajectory, getAllTrajectoryAsync } from 'slices/trajectorySlice';

import { selectUser } from 'slices/authenticationSlice';

import type { UsersTrajectoryType, TrajectoryType } from 'types';

import {
    TableRefiner,
    Pagination,
    Prev,
    Next,
    InputPagination,
    AddIcon,
    RemoveIcon,
} from 'styles/common';

export const UsersTrajectoryTable: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageSize = 10;

    const itemsUsersTrajectory = useSelector(selectUsersTrajectory);
    const itemsTrajectory = useSelector(selectTrajectory);
    const [orderBy, setOrderBy] = useState<string | number>('id');
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    let page = useSelector(selectPage);
    const total = useSelector(selectTotal);
    const totalPage = Math.ceil(total / pageSize);
    const user = useSelector(selectUser);
    const usersId = useSelector(selectUsersId);

    useEffect(() => {
        dispatch(getAllTrajectoryAsync());
        dispatch(getAllUsersTrajectoryAsync(String(usersId)));
    }, [dispatch, usersId]);

    const headers = [
        {
            label: t('components.TrajectoryTable.headers.name'),
            id: 'name',
            sortable: true,
        },
        {
            label: t('components.UsersTrajectoryTable.headers.status'),
            id: 'status',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const removeUsersTrajectoryHandler = (trajectoryId: number) => {
        const index = itemsUsersTrajectory.findIndex(
            (usersTrajectory) => usersTrajectory.trajectoryId === trajectoryId,
        );
        if (window.confirm('Êtes-vous sûr de vouloir retirer cette accès?')) {
            dispatch(
                deleteUsersTrajectoryAsync({ usersTrajectory: itemsUsersTrajectory[index], index }),
            );
        }
    };

    const postHandler = (trajectoryId: number, _index: number) => {
        const empty = {} as UsersTrajectoryType;
        const usersTrajectory = {
            ...empty,
            trajectoryId,
            usersId,
        };

        dispatch(postUsersTrajectoryAsync({ usersTrajectory }));
    };

    const readOnlyRow = (item: TrajectoryType, index: number) => {
        const res = {
            id: item.id,
            name: item.name,
            status: t(
                `components.UsersTrajectoryTable.status.${itemsUsersTrajectory.some(
                    (usersTrajectory) => usersTrajectory.trajectoryId === item.id,
                )}`,
            ),
            controls: <></>,
        };

        if (user && user.role === 'admin') {
            res.controls = (
                <>
                    {!itemsUsersTrajectory.some(
                        (usersTrajectory) => usersTrajectory.trajectoryId === item.id,
                    ) && (
                        <TextButton onClick={() => postHandler(item.id, index)}>
                            {t('components.Table.add')}
                            <IconSuffix icon={<AddIcon size="16" />} />
                        </TextButton>
                    )}
                    {itemsUsersTrajectory.some(
                        (usersTrajectory) => usersTrajectory.trajectoryId === item.id,
                    ) && (
                        <TextButton onClick={() => removeUsersTrajectoryHandler(item.id)}>
                            {t('components.Table.remove')}
                            <IconSuffix icon={<RemoveIcon size="16" />} />
                        </TextButton>
                    )}
                </>
            );
        }

        return res;
    };

    const preparedRows = (items: TrajectoryType[]) =>
        items.map((item: TrajectoryType, index: number) => readOnlyRow(item, index));

    const prev = () => {
        if (page > 0) {
            page = page - 1;
            dispatch(changeToAmount(page));
        }
    };

    const next = () => {
        if (page < totalPage) {
            page = page + 1;
            dispatch(changeToAmount(page));
        }
    };

    const pageNumberHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPage && newPageNumber > 0) {
            page = newPageNumber;
            dispatch(changeToAmount(page));
        }
    };

    const orderHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderBy(by);
        setOrder(sortOrder);
        page = 1;
        dispatch(changeToAmount(page));
    };

    return (
        <TableRefiner>
            <Table
                headers={headers}
                rows={preparedRows(itemsTrajectory)}
                sortOptions={{ sortBy: orderBy, sortOrder: order }}
                onSort={(currentSortOptions) => orderHandler(currentSortOptions)}
            />
            {total > 10 && (
                <Pagination>
                    {page > 1 && <Prev onClick={prev}>{t('components.Table.prev')}</Prev>}
                    <InputPagination
                        value={page}
                        type="number"
                        onChange={(e: React.FormEvent) =>
                            pageNumberHandler((e.target as HTMLInputElement).value)
                        }
                    />{' '}
                    / {totalPage}
                    {page < totalPage && <Next onClick={next}>{t('components.Table.next')}</Next>}
                </Pagination>
            )}
        </TableRefiner>
    );
};
