import React, { Suspense, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { setDefaultLocale } from 'react-datepicker';

import { useAppDispatch } from './hooks/reduxHooks';
import 'react-datepicker/dist/react-datepicker.css';

import AppRoutes from 'AppRoutes';
import { Header } from 'components/Header';
import { FullViewLoader } from 'components/FullViewLoader';
import { theme } from 'constants/theme';
import { whoami } from 'slices/authenticationSlice';

const Layout = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;
`;

const App = (): JSX.Element => {
    setDefaultLocale('fr-CA');

    const dispatch = useAppDispatch();
    const token: string | null = localStorage.getItem('api_token');

    useEffect(() => {
        if (token) {
            dispatch(whoami);
        }
    }, [dispatch, token]);

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Suspense fallback={<FullViewLoader />}>
                    <Header />
                    <AppRoutes />
                </Suspense>
            </Layout>
        </ThemeProvider>
    );
};

export default App;
