import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextField, Button } from '@osedea/reactor';

import { useAppDispatch } from '../../hooks/reduxHooks';
import { CenteredContainer } from 'styles/common';
import { sendResetPasswordEmail } from 'slices/authenticationSlice';
import { LoginCard, Title } from './styles';
import type { RootState } from 'store';

interface IProps {}

const PasswordResetRequest = (_props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    // If user is authenticated, redirect to home
    useSelector((state: RootState) => {
        if (state.authentication.user) {
            navigate('/');
        }
    });

    const error = useSelector((state: RootState) => state.authentication.error, shallowEqual);

    const authHandler = () => {
        setSubmitted(true);
        dispatch(sendResetPasswordEmail(email));
    };

    return (
        <>
            <Helmet>
                <title>{t('pages.PasswordReset.title')}</title>
            </Helmet>
            <CenteredContainer>
                <Title>{t('pages.PasswordReset.title')}</Title>
                <LoginCard>
                    <TextField
                        name="email"
                        type="text"
                        label={t('pages.Login.email')}
                        onChange={(e: React.FormEvent) =>
                            setEmail((e.target as HTMLInputElement).value)
                        }
                        value={email}
                    />
                    <Button disabled={!email.trim()} onClick={authHandler}>
                        {t('pages.PasswordReset.submit')}
                    </Button>
                    {isSubmitted && error ? t('general.error') : null}
                    {isSubmitted && !error ? t('pages.PasswordReset.messageSent') : null}
                </LoginCard>
            </CenteredContainer>
        </>
    );
};

export default PasswordResetRequest;
