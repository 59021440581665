import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container } from 'styles/common';
import { PatientTable } from 'components/PatientTable';

export const Patient: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('pages.Dashboard.title')}</title>
            </Helmet>
            <Container>
                <PatientTable />
            </Container>
        </>
    );
};
