import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Edit, IconSuffix, Table, TextButton, Select, TextField } from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import locale from 'date-fns/locale/fr-CA';
import dayjs from 'dayjs';

import type { SelectOption } from '@osedea/reactor/dist/components/textFields/types';

import { selectUser } from 'slices/authenticationSlice';

import {
    selectEntries,
    putEntryAsync,
    postEntryAsync,
    getAllEntriesAsync,
    selectPatient,
    removeEntry,
    addEntry,
    selectPostSuccessful,
    resetPostSuccessful,
    selectPutSuccessful,
    resetPutSuccessful,
    deleteEntryAsync,
} from 'slices/entrySlice';

import {
    getStepAsync,
    selectStep,
    selectTotal,
    changeToAmount,
    selectPage,
    selectQuery,
    defaultPageSize as stepDefaultPageSize,
} from 'slices/stepSlice';

import type { StepType, UserType, EntryType } from 'types';

import { TableRefiner, Pagination, Prev, Next, InputPagination, RemoveIcon } from 'styles/common';
import { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

export const EntryTable: React.FunctionComponent = () => {
    registerLocale('fr-CA', locale);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageSize = stepDefaultPageSize;

    const [stepOngoing, setStepOngoing] = React.useState({} as StepType);
    const [entryOngoing, setEntryOngoing] = React.useState({
        entryDate: dayjs().toDate().toUTCString(),
        entryInfo: '',
    } as EntryType);

    const itemsStep = useSelector(selectStep);
    const itemsEntry = useSelector(selectEntries);
    const query = useSelector(selectQuery);
    let page = useSelector(selectPage);
    const postSuccessful = useSelector(selectPostSuccessful);
    const putSuccessful = useSelector(selectPutSuccessful);
    const total = useSelector(selectTotal);
    const totalPage = Math.ceil(total / pageSize);
    const user = useSelector(selectUser);
    const patientId = useSelector(selectPatient);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const [orderBy, setOrderBy] = useState<string | number>('order');
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');

    useEffect(() => {
        dispatch(getStepAsync({ page, pageSize, query, order, orderBy }));
        dispatch(getAllEntriesAsync(patientId.toString()));
        if (postSuccessful) {
            setActiveIndex(null);
            dispatch(resetPostSuccessful());
        }
        if (putSuccessful) {
            setActiveIndex(null);
            dispatch(resetPutSuccessful());
        }
    }, [dispatch, page, query, patientId, postSuccessful, putSuccessful, order, orderBy]);

    const headers = [
        {
            label: t('components.StepTable.headers.order'),
            id: 'order',
            sortable: true,
        },
        {
            label: t('components.StepTable.headers.name'),
            id: 'name',
        },
        {
            label: t('components.EntryTable.headers.info'),
            id: 'entry',
        },
        {
            label: t('components.EntryTable.headers.comment'),
            id: 'comment',
        },
        {
            label: t('components.StepTable.headers.timeLimit'),
            id: 'timeLimit',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const setActiveIndexHandler = (index: number, indexEntry: number) => {
        if (indexEntry === -1) {
            dispatch(addEntry(itemsStep[index].id));
            setEntryOngoing({
                stepId: itemsStep[index].id,
                entryDate: dayjs().toDate().toUTCString(),
                entryInfo: '',
                patientId,
            } as EntryType);
        } else {
            setEntryOngoing(itemsEntry[indexEntry]);
        }
        setStepOngoing(itemsStep[index]);
        setActiveIndex(index);
    };

    const checkIfStepHasEntry = (indexEntry: number) => indexEntry !== -1;

    const removeEntryHandler = (indexEntry: number) => {
        if (
            checkIfStepHasEntry(indexEntry) &&
            window.confirm('Êtes-vous sûr de vouloir supprimer cette information?')
        ) {
            dispatch(
                deleteEntryAsync({
                    entry: itemsEntry[indexEntry],
                    indexEntry,
                }),
            );
        }
    };

    const writePermission = (user1: UserType) =>
        user1.role === 'admin' || user1.role === 'technicien' || user1.role === 'manager';

    const readOnlyRow = (item: StepType, index: number, indexEntry: number) => {
        let comment = '';
        let entry = '';

        if (checkIfStepHasEntry(indexEntry)) {
            comment = itemsEntry[indexEntry].comment;
            if (item.type === 'date') {
                entry = dayjs(itemsEntry[indexEntry].entryDate).format('YYYY/MM/DD');
            } else if (item.type === 'info') {
                entry = itemsEntry[indexEntry].entryInfo;
            } else if (item.type === 'all') {
                entry = `${dayjs(itemsEntry[indexEntry].entryDate).format('YYYY/MM/DD')} - ${
                    itemsEntry[indexEntry].entryInfo
                }`;
            }
        }

        const res = {
            id: item.id,
            name: item.name,
            order: item.order,
            timeLimit: item.timeLimit,
            entry,
            comment,
            controls: '' as string | React.JSX.Element,
        };

        if (activeIndex) {
            res.controls = '';
        } else {
            if (user && writePermission(user)) {
                res.controls = (
                    <>
                        <TextButton onClick={() => setActiveIndexHandler(index, indexEntry)}>
                            {t('components.Table.edit')}
                            <IconSuffix icon={<Edit />} />
                        </TextButton>
                        {checkIfStepHasEntry(indexEntry) && (
                            <TextButton onClick={() => removeEntryHandler(indexEntry)}>
                                {t('components.Table.remove')}
                                <IconSuffix icon={<RemoveIcon size="16" />} />
                            </TextButton>
                        )}
                    </>
                );
            }
        }

        return res;
    };

    const putHandler = (index: number) => {
        dispatch(putEntryAsync({ entry: entryOngoing, index }));
    };

    const postHandler = (index: number) => {
        const entry = {
            ...entryOngoing,
            patientId,
            entryDate: entryOngoing.entryDate,
            stepId: stepOngoing.id,
        };
        dispatch(postEntryAsync({ entry, index }));
    };

    const cancelAddHandler = (index: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler l'ajout?")) {
            dispatch(removeEntry(index));
            setActiveIndex(null);
        }
    };

    const cancelHandler = () => {
        setActiveIndex(null);
        if (window.confirm('Êtes-vous sûr de vouloir annuler la modification?')) {
            setActiveIndex(null);
        }
    };

    const dateHandler = (value: Date, _indexEntry: number) => {
        const entry = {
            ...entryOngoing,
            entryDate: value.toUTCString(),
            entryInfo: '',
        };
        setEntryOngoing(entry);
    };

    const infoHandler = (value: string, _indexEntry: number) => {
        const entry = {
            ...entryOngoing,
            entryInfo: value,
        };
        setEntryOngoing(entry);
    };

    const commentHandler = (value: string, _indexEntry: number) => {
        const entry = {
            ...entryOngoing,
            comment: value,
        };
        setEntryOngoing(entry);
    };

    const foundOption = (value: string): string[] => value.match(/\(.*\)/g) ?? [];

    const getOption = (value: string) => {
        const foundOptions = foundOption(value);
        let found = foundOptions[foundOptions.length - 1];
        if (!found) {
            return [
                {
                    label: t('entity.StepType.Select'),
                    value: '',
                    disabled: true,
                },
            ] as SelectOption[];
        }
        found = found.substr(1, found.length - 2);
        let array = found.split('/');
        if (array.length === 1) {
            array = found.split(', ');
        }
        const options = [
            {
                label: t('entity.StepType.Select'),
                value: '',
                disabled: true,
            },
            ...array.map((option) => ({ label: option, value: option })),
        ] as SelectOption[];
        return options;
    };

    const editableRow = (item: StepType, _index: number, indexEntry: number) => {
        let entry;

        if (checkIfStepHasEntry(indexEntry)) {
            if (stepOngoing.type === 'date') {
                entry = (
                    <DatePicker
                        dateFormat="yyyy/MM/dd"
                        selected={dayjs(
                            entryOngoing.entryDate || new Date().toUTCString(),
                        ).toDate()}
                        onChange={(value: Date) => dateHandler(value, indexEntry)}
                    />
                );
            } else if (stepOngoing.type === 'info') {
                entry = (
                    <Select
                        name="info"
                        options={getOption(stepOngoing.name)}
                        label=""
                        value={entryOngoing.entryInfo || ''}
                        onChange={(e: React.FormEvent) =>
                            infoHandler((e.target as HTMLInputElement).value, indexEntry)
                        }
                        variant="outlined"
                    />
                );
            } else if (stepOngoing.type === 'all') {
                entry = (
                    <>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            selected={dayjs(
                                entryOngoing.entryDate || new Date().toUTCString(),
                            ).toDate()}
                            onChange={(value: Date) => dateHandler(value, indexEntry)}
                        />
                        <Select
                            name="info"
                            options={getOption(stepOngoing.name)}
                            label=""
                            value={entryOngoing.entryInfo || ''}
                            onChange={(e: React.FormEvent) =>
                                infoHandler((e.target as HTMLInputElement).value, indexEntry)
                            }
                            variant="outlined"
                        />
                    </>
                );
            }
        }

        return {
            id: item.id,
            name: stepOngoing.name,
            entry: entry ?? '',
            comment: (
                <TextField
                    name="entryComment"
                    type="text"
                    label=""
                    onChange={(e: React.FormEvent) =>
                        commentHandler((e.target as HTMLInputElement).value, indexEntry)
                    }
                    value={entryOngoing.comment || ''}
                />
            ),
            controls:
                indexEntry === undefined ||
                itemsEntry[indexEntry] === undefined ||
                itemsEntry[indexEntry].id === undefined ? (
                    <>
                        <Button onClick={() => postHandler(indexEntry)}>
                            {t('components.Table.save')}
                        </Button>
                        <Button
                            style={{ marginLeft: '8px' }}
                            onClick={() => cancelAddHandler(indexEntry)}
                            variant="destructive"
                        >
                            {t('components.Table.cancel')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => putHandler(indexEntry)}>
                            {t('components.Table.save')}
                        </Button>
                        <Button
                            style={{ marginLeft: '8px' }}
                            onClick={() => cancelHandler()}
                            variant="destructive"
                        >
                            {t('components.Table.cancel')}
                        </Button>
                    </>
                ),
        };
    };

    const preparedRows = (items: StepType[]) =>
        items.map((item: StepType, index: number) => {
            const indexEntry = itemsEntry.findIndex((entry) => entry.stepId === item.id);
            if (index === activeIndex) {
                return editableRow(item, index, indexEntry);
            }

            return readOnlyRow(item, index, indexEntry);
        });

    const prev = () => {
        if (page > 0) {
            page = page - 1;
            dispatch(changeToAmount(page));
        }
    };

    const next = () => {
        if (page < totalPage) {
            page = page + 1;
            dispatch(changeToAmount(page));
        }
    };

    const pageNumberHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPage && newPageNumber > 0) {
            page = newPageNumber;
            dispatch(changeToAmount(page));
        }
    };

    /**
     * handle the sorting option of the table by updating the component
     * state.
     */
    function orderHandler(currentSortingOptions: SortOptions): void {
        console.log('coucou', currentSortingOptions);
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'order' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'INACTIVE' : currentSortingOptions.sortOrder;
        setOrderBy(by);
        setOrder(sortOrder);
        page = 1;
        dispatch(changeToAmount(page));
    }

    return (
        <TableRefiner>
            <Table
                headers={headers}
                rows={preparedRows(itemsStep)}
                sortOptions={{ sortBy: orderBy, sortOrder: order }}
                onSort={(currentSortOptions) => orderHandler(currentSortOptions)}
            />
            {total > pageSize && (
                <Pagination>
                    {page > 1 && <Prev onClick={prev}>{t('components.Table.prev')}</Prev>}
                    <InputPagination
                        value={page}
                        type="number"
                        onChange={(e: React.FormEvent) =>
                            pageNumberHandler((e.target as HTMLInputElement).value)
                        }
                    />{' '}
                    / {totalPage}
                    {page < totalPage && <Next onClick={next}>{t('components.Table.next')}</Next>}
                </Pagination>
            )}
        </TableRefiner>
    );
};
