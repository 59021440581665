import type { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { StepType, StepTypeHistory } from 'types';
import request from 'utils/request';

export const defaultPageSize = 100;

export type StepSliceType = {
    step: StepType[];
    error: string;
    total: number;
    page: number;
    query: string;
    stepHistory: StepTypeHistory[];
    errorHistory: string;
    totalHistory: number;
    pageHistory: number;
    trajectoryId: number;
    postSuccessful: boolean;
    putSuccessful: boolean;
    deleteSuccessful: boolean;
};

export const initialState: StepSliceType = {
    step: [],
    error: '',
    total: 0,
    page: 1,
    query: '',
    stepHistory: [],
    errorHistory: '',
    totalHistory: 0,
    pageHistory: 1,
    trajectoryId: -1,
    postSuccessful: false,
    putSuccessful: false,
    deleteSuccessful: false,
};

// Requests

export const getStepRequest = (
    page: number,
    pageSize: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: StepType[]; total: number; next: string }>(`/step`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const postStepRequest = (step: StepType) =>
    request<{ data: StepType }>(`/step`, {
        method: 'POST',
        data: step,
    });

export const putStepRequest = (step: StepType) =>
    request<{ data: StepType }>(`/step/${step.id}`, {
        method: 'PUT',
        data: step,
    });

export type MoveStepInput = { id: number; oldOrder: number; newOrder: number };

export const moveStepRequest = (trajectoryId: number, moveStepInputs: MoveStepInput[]) =>
    request<{ data: { id: number; order: number } }>(`/step/${trajectoryId}/move-step`, {
        method: 'PUT',
        data: { steps: moveStepInputs },
    });

export const getStepHistoryRequest = (
    page: number,
    pageSize: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: StepTypeHistory[]; total: number }>(`/audit-step`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const deleteStepRequest = (step: StepType) =>
    request(`/step/${step.id}`, {
        method: 'DELETE',
    });

// Thunks

export const getStepAsync = createAsyncThunk<
    { data: StepType[]; total: number },
    { page: number; pageSize: number; query?: string; orderBy?: string | number; order?: string }
>('step', async ({ page, pageSize, query, orderBy, order }) => {
    const response = await getStepRequest(page, pageSize, query, orderBy, order);
    return response.data;
});

export const postStepAsync = createAsyncThunk<
    { value: StepType; index: number },
    { step: StepType; index: number }
>('post/step', async ({ step, index }) => {
    const response = await postStepRequest(step);
    return { value: response.data.data, index };
});

export const putStepAsync = createAsyncThunk<
    { value: StepType; index: number },
    { step: StepType; index: number }
>('put/step', async ({ step, index }) => {
    const response = await putStepRequest(step);
    return { value: response.data.data, index };
});

export const moveStepAsync = createAsyncThunk<
    void,
    { trajectoryId: number; moveStepInputs: MoveStepInput[] }
>('put/step/move', async ({ trajectoryId, moveStepInputs }) => {
    await moveStepRequest(trajectoryId, moveStepInputs);
});

export const deleteStepAsync = createAsyncThunk<
    { index: number },
    { step: StepType; index: number }
>('delete/step', async ({ step, index }) => {
    await deleteStepRequest(step)
        // hotfix to have a message when a step is deleted with data
        // Cf. https://github.com/Osedea/chum_parcours-patient-frontend/pull/226
        .catch((error) => {
            if (error.status === 500) {
                throw new Error(
                    'Erreur lors de la tentative de suppression de cette étape, il y a probablement des données associées.',
                );
            }
        });
    return { index };
});

export const getStepHistoryAsync = createAsyncThunk<
    { data: StepTypeHistory[]; total: number },
    { page: number; pageSize: number; query?: string; orderBy?: string | number; order?: string }
>('stepHistory', async ({ page, pageSize, query, orderBy, order }) => {
    const response = await getStepHistoryRequest(page, pageSize, query, orderBy, order);
    return response.data;
});

export const logoutStep = () => (dispatch: Dispatch) => {
    dispatch(cleanStep());
    dispatch(cleanStepHistory());
};

export const getAllStepAsync = (query?: string) => async (dispatch: Dispatch) => {
    let page = 1;
    let response;
    dispatch(cleanStep());
    do {
        response = await getStepRequest(page, defaultPageSize, query);
        dispatch(addAllStep(response.data.data));
        page++;
    } while (response.data.next !== null);
    return response.data;
};

export const slice = createSlice({
    name: 'step',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanStep(state) {
            state.step = [];
            state.error = '';
        },
        addStep(state) {
            state.step = [{} as StepType];
        },
        removeStep(state, action: PayloadAction<number>) {
            state.step = state.step.slice(0, action.payload);
        },
        changeToAmount(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        cleanStepHistory(state) {
            state.stepHistory = [];
            state.error = '';
        },
        changeToAmountHistory(state, action: PayloadAction<number>) {
            state.pageHistory = action.payload;
        },
        changeQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
            state.page = 1;
        },
        changeTrajectory(state, action: PayloadAction<number>) {
            state.trajectoryId = action.payload;
        },
        addAllStep(state, action: PayloadAction<StepType[]>) {
            state.step = [...state.step, ...action.payload];
            state.error = '';
        },
        resetPostSuccessful(state) {
            state.postSuccessful = !state.postSuccessful;
        },
        resetPutSuccessful(state) {
            state.putSuccessful = !state.putSuccessful;
        },
        resetDeleteSuccessful(state) {
            state.deleteSuccessful = !state.deleteSuccessful;
        },
    },
    extraReducers(builder) {
        builder.addCase(getStepAsync.fulfilled, (state, { payload }) => {
            state.step = payload.data;
            state.total = payload.total;
            state.error = '';
        });
        builder.addCase(getStepAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(postStepAsync.fulfilled, (state, { payload }) => {
            state.step[payload.index] = payload.value;
            state.postSuccessful = true;
            state.error = '';
        });
        builder.addCase(postStepAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(putStepAsync.fulfilled, (state, { payload }) => {
            state.step[payload.index] = payload.value;
            state.putSuccessful = true;
            state.error = '';
        });
        builder.addCase(putStepAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(deleteStepAsync.fulfilled, (state, { payload }) => {
            state.step = state.step.filter((_item, index) => index !== payload.index);
            state.deleteSuccessful = true;
            state.error = '';
        });
        builder.addCase(deleteStepAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(getStepHistoryAsync.fulfilled, (state, { payload }) => {
            state.stepHistory = payload.data;
            state.totalHistory = payload.total;
            state.error = '';
        });
        builder.addCase(getStepHistoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });

        builder.addCase(moveStepAsync.fulfilled, (state, _action) => {
            state.putSuccessful = true;
            state.error = '';
        });
        builder.addCase(moveStepAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const {
    resetError,
    changeToAmount,
    changeToAmountHistory,
    cleanStepHistory,
    changeQuery,
    addStep,
    removeStep,
    cleanStep,
    changeTrajectory,
    addAllStep,
    resetPostSuccessful,
    resetPutSuccessful,
    resetDeleteSuccessful,
} = slice.actions;

export default slice.reducer;

// Selectors

export const selectStep = (state: RootState) => state.step.step;
export const selectTotal = (state: RootState) => state.step.total;
export const selectError = (state: RootState) => state.step.error;
export const selectPage = (state: RootState) => state.step.page;
export const selectQuery = (state: RootState) => state.step.query;
export const selectStepHistory = (state: RootState) => state.step.stepHistory;
export const selectTotalHistory = (state: RootState) => state.step.totalHistory;
export const selectPageHistory = (state: RootState) => state.step.pageHistory;
export const selectTrajectory = (state: RootState) => state.step.trajectoryId;
export const selectPostSuccessful = (state: RootState) => state.step.postSuccessful;
export const selectPutSuccessful = (state: RootState) => state.step.putSuccessful;
export const selectDeleteSuccessful = (state: RootState) => state.step.deleteSuccessful;
