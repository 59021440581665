import type { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import type { RootState } from 'store';
import type { EntryType, EntryTypeHistory } from 'types';
import request from 'utils/request';

const pageSize = 100;

export type EntrySliceType = {
    entries: EntryType[];
    error: string;
    total: number;
    page: number;
    query: string;
    entriesHistory: EntryTypeHistory[];
    errorHistory: string;
    totalHistory: number;
    pageHistory: number;
    patientId: number;
    postSuccessful: boolean;
    putSuccessful: boolean;
};

export const initialState: EntrySliceType = {
    entries: [],
    error: '',
    total: 0,
    page: 1,
    query: '',
    entriesHistory: [],
    errorHistory: '',
    totalHistory: 0,
    pageHistory: 1,
    patientId: -1,
    postSuccessful: false,
    putSuccessful: false,
};

// Requests

export const getEntriesRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: EntryType[]; total: number; next: string }>(`/entry`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const postEntryRequest = (entry: EntryType) =>
    request<{ data: EntryType }>(`/entry`, {
        method: 'POST',
        data: entry,
    });

export const putEntryRequest = (entry: EntryType) =>
    request<{ data: EntryType }>(`/entry/${entry.id}`, {
        method: 'PUT',
        data: entry,
    });

export const getEntriesHistoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: EntryTypeHistory[]; total: number }>(`/audit-entry`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const deleteEntryRequest = (entry: EntryType) =>
    request(`/entry/${entry.id}`, {
        method: 'DELETE',
    });

export const createEventRequest = (id: number) =>
    request(`/entry/event`, {
        method: 'POST',
        data: {
            id,
        },
    });

// Thunks

export const getEntriesAsync = createAsyncThunk<
    { data: EntryType[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('entry', async ({ page, query, orderBy, order }) => {
    const response = await getEntriesRequest(page, query, orderBy, order);
    return response.data;
});

export const postEntryAsync = createAsyncThunk<
    { value: EntryType; index: number },
    { entry: EntryType; index: number }
>('post/entry', async ({ entry, index }) => {
    const response = await postEntryRequest(entry);
    return { value: response.data.data, index };
});

export const putEntryAsync = createAsyncThunk<
    { value: EntryType; index: number },
    { entry: EntryType; index: number }
>('put/entry', async ({ entry, index }) => {
    const response = await putEntryRequest(entry);
    return { value: response.data.data, index };
});

export const deleteEntryAsync = createAsyncThunk<
    { indexEntry: number },
    { entry: EntryType; indexEntry: number }
>('delete/entry', async ({ entry, indexEntry }) => {
    await deleteEntryRequest(entry);
    return { indexEntry };
});

export const getEntriesHistoryAsync = createAsyncThunk<
    { data: EntryTypeHistory[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('entryHistory', async ({ page, query, orderBy, order }) => {
    const response = await getEntriesHistoryRequest(page, query, orderBy, order);
    return response.data;
});

export const createEventAsync = createAsyncThunk<void, { id: number }>(
    'createEventAsync',
    async ({ id }) => {
        await createEventRequest(id);
    },
);

export const logoutEntry = () => (dispatch: Dispatch) => {
    dispatch(cleanEntry());
    dispatch(cleanEntryHistory());
};

export const getAllEntriesAsync = (query?: string) => async (dispatch: Dispatch) => {
    let page = 1;
    let response;
    dispatch(cleanEntry());
    do {
        response = await getEntriesRequest(page, query);
        dispatch(addAllEntries(response.data.data));
        page++;
    } while (response.data.next !== null);
    return response.data;
};

export const slice = createSlice({
    name: 'entry',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanEntry(state) {
            state.entries = [];
            state.error = '';
        },
        addEntry(state, action: PayloadAction<number>) {
            state.entries.push({
                stepId: action.payload,
                entryDate: String(dayjs().toDate().toUTCString()),
                entryInfo: '',
            } as EntryType);
        },
        removeEntry(state, action: PayloadAction<number>) {
            state.entries = state.entries.slice(0, action.payload);
        },
        changeToAmount(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        cleanEntryHistory(state) {
            state.entriesHistory = [];
            state.error = '';
        },
        changeToAmountHistory(state, action: PayloadAction<number>) {
            state.pageHistory = action.payload;
        },
        changeQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
            state.page = 1;
        },
        addAllEntries(state, action: PayloadAction<EntryType[]>) {
            const existentEntries = state.entries;
            const entriesToAdd = action.payload.filter(
                (entryToAdd) =>
                    !existentEntries.some((existentEntry) => existentEntry.id === entryToAdd.id),
            );

            state.entries = [...state.entries, ...entriesToAdd];
            state.error = '';
        },
        changePatient(state, action: PayloadAction<number>) {
            state.patientId = action.payload;
        },
        resetPostSuccessful(state) {
            state.postSuccessful = !state.postSuccessful;
        },
        resetPutSuccessful(state) {
            state.putSuccessful = !state.putSuccessful;
        },
    },
    extraReducers(builder) {
        builder.addCase(getEntriesAsync.fulfilled, (state, { payload }) => {
            state.entries = payload.data;
            state.total = payload.total;
            state.error = '';
        });
        builder.addCase(getEntriesAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(postEntryAsync.fulfilled, (state, { payload }) => {
            state.entries[payload.index] = payload.value;
            state.postSuccessful = true;
            state.error = '';
        });
        builder.addCase(postEntryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(putEntryAsync.fulfilled, (state, { payload }) => {
            state.entries[payload.index] = payload.value;
            state.putSuccessful = true;
            state.error = '';
        });
        builder.addCase(putEntryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(deleteEntryAsync.fulfilled, (state, { payload }) => {
            state.entries = state.entries.filter((_item, index) => index !== payload.indexEntry);
            state.error = '';
        });
        builder.addCase(deleteEntryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(getEntriesHistoryAsync.fulfilled, (state, { payload }) => {
            state.entriesHistory = payload.data;
            state.totalHistory = payload.total;
            state.error = '';
        });
        builder.addCase(getEntriesHistoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const {
    resetError,
    changeToAmount,
    changeToAmountHistory,
    cleanEntryHistory,
    changeQuery,
    addEntry,
    removeEntry,
    cleanEntry,
    addAllEntries,
    changePatient,
    resetPostSuccessful,
    resetPutSuccessful,
} = slice.actions;

export default slice.reducer;

// Selectors

export const selectEntries = (state: RootState) => state.entry.entries;
export const selectTotal = (state: RootState) => state.entry.total;
export const selectError = (state: RootState) => state.entry.error;
export const selectPage = (state: RootState) => state.entry.page;
export const selectQuery = (state: RootState) => state.entry.query;
export const selectEntriesHistory = (state: RootState) => state.entry.entriesHistory;
export const selectTotalHistory = (state: RootState) => state.entry.totalHistory;
export const selectPageHistory = (state: RootState) => state.entry.pageHistory;
export const selectPatient = (state: RootState) => state.entry.patientId;
export const selectPostSuccessful = (state: RootState) => state.entry.postSuccessful;
export const selectPutSuccessful = (state: RootState) => state.entry.putSuccessful;
