import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Edit,
    IconSuffix,
    Table,
    TextButton,
    TextField,
    Select,
    IDesignSystem,
} from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';

import type { SelectOption } from '@osedea/reactor/dist/components/textFields/types';

import type { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import {
    getStepAsync,
    selectStep,
    selectTotal,
    changeToAmount,
    selectPage,
    selectQuery,
    putStepAsync,
    postStepAsync,
    addStep,
    removeStep,
    selectStepHistory,
    getStepHistoryAsync,
    cleanStepHistory,
    selectTotalHistory,
    selectPageHistory,
    deleteStepAsync,
    changeToAmountHistory,
    selectTrajectory,
    selectPostSuccessful,
    resetPostSuccessful,
    selectPutSuccessful,
    resetPutSuccessful,
    resetDeleteSuccessful,
    defaultPageSize as stepDefaultPageSize,
    moveStepAsync,
    MoveStepInput,
    selectDeleteSuccessful,
    selectError,
    resetError,
} from 'slices/stepSlice';

import { selectUser } from 'slices/authenticationSlice';

import type { StepType, StepTypeHistory } from 'types';

import {
    TableRefiner,
    Pagination,
    Prev,
    Next,
    InputPagination,
    Title,
    ControlWrapper,
    AddIcon,
    HistoryIcon,
    RemoveIcon,
    MoveUpArrowIcon,
    MoveDownArrowIcon,
    Container,
} from 'styles/common';
import { action, stepIndicator } from 'utils/type.utils';
import { useTheme } from 'styled-components';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const StepTable: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const theme = useTheme() as IDesignSystem;
    const dispatch = useDispatch();
    const pageSize = stepDefaultPageSize;

    // result query selectors
    const error = useSelector(selectError);
    const items = useSelector(selectStep);
    const itemsHistory = useSelector(selectStepHistory);
    const query = useSelector(selectQuery);

    // page related state
    let page = useSelector(selectPage);
    let pageHistory = useSelector(selectPageHistory);
    const postSuccessful = useSelector(selectPostSuccessful);
    const putSuccessful = useSelector(selectPutSuccessful);
    const deleteSuccessful = useSelector(selectDeleteSuccessful);
    const total = useSelector(selectTotal);
    const totalPage = Math.ceil(total / pageSize);
    const totalHistory = useSelector(selectTotalHistory);
    const user = useSelector(selectUser);
    const totalPageHistory = Math.ceil(totalHistory / pageSize);
    const trajectoryId = useSelector(selectTrajectory);

    // active edition states
    const [tableEditionState, setTableEditionState] = React.useState<'normal' | 'order'>('normal');
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [stepOngoing, setStepOngoing] = React.useState({} as StepType);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    // step request related state
    const [queryHistory, setQueryHistory] = useState(0);
    const [orderBy, setOrderBy] = useState<string | number>('order');
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    const [orderByHistory, setOrderByHistory] = useState<string | number>('id');
    const [orderHistory, setOrderHistory] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');

    // Error management state
    const [nameError, nameErrorSetter] = useState(false);
    const [stepTimeLimitError, stepTimeLimitErrorSetter] = useState(false);
    const [typeError, typeErrorSetter] = useState(false);
    const [showDashboardError, showDashboardErrorSetter] = useState(false);
    const [indicatorError, indicatorErrorSetter] = useState(false);

    useEffect(() => {
        dispatch(getStepAsync({ page, pageSize, query, orderBy, order }));
        if (postSuccessful) {
            setActiveIndex(null);
            setOrderBy('order');
            setOrder('ASC');
            dispatch(resetPostSuccessful());
        }
        if (putSuccessful) {
            setActiveIndex(null);
            dispatch(resetPutSuccessful());
        }

        if (deleteSuccessful) {
            dispatch(resetDeleteSuccessful());
        }
    }, [dispatch, page, query, orderBy, order, postSuccessful, putSuccessful, deleteSuccessful]);

    useEffect(() => {
        // hotfix to have a message when a step is deleted with data
        // Cf. https://github.com/Osedea/chum_parcours-patient-frontend/pull/226
        if (error) {
            alert(error);
        }
        dispatch(resetError());
    }, [error]);

    // table edition state setup
    useEffect(() => {
        if (tableEditionState === 'order') {
            setActiveIndex(null);
            setOrderBy('order');
            setStepOngoing({} as StepType);
        }
    }, [tableEditionState]);

    const getStepHistoryRequest = () => {
        dispatch(cleanStepHistory());
        dispatch(getStepHistoryAsync({ page: pageHistory, pageSize, query: String(queryHistory) }));
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const types = [
        {
            label: t('entity.StepType.selectType'),
            value: '',
            disabled: true,
        },
        {
            label: t('entity.StepType.date'),
            value: 'date',
        },
        {
            label: t('entity.StepType.info'),
            value: 'info',
        },
    ] as SelectOption[];

    const indicators = [
        {
            label: t('components.StepTable.indicator.selectIndicator'),
            value: '',
            disabled: true,
        },
        {
            label: t('components.StepTable.indicator.start'),
            value: 'start',
        },
        {
            label: t('components.StepTable.indicator.end'),
            value: 'end',
        },
        {
            label: t('components.StepTable.indicator.none'),
            value: 'none',
        },
    ] as SelectOption[];

    const showDashboardOptions = [
        {
            label: t('components.StepTable.showDashboard.selectShoweDashboard'),
            value: '',
            disabled: true,
        },
        {
            label: t('components.StepTable.showDashboard.true'),
            value: 'true',
        },
        {
            label: t('components.StepTable.showDashboard.false'),
            value: 'false',
        },
    ] as SelectOption[];

    const headers = [
        {
            label: t('components.StepTable.headers.order'),
            id: 'order',
            sortable: true,
        },
        {
            label: t('components.StepTable.headers.name'),
            id: 'name',
            sortable: true,
        },
        {
            label: t('components.StepTable.headers.type'),
            id: 'type',
        },
        {
            label: t('components.StepTable.headers.showDashboard'),
            id: 'showDashboard',
        },
        {
            label: t('components.StepTable.headers.indicator'),
            id: 'indicator',
        },
        {
            label: t('components.StepTable.headers.timeLimit'),
            id: 'timeLimit',
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const headersHistory = [
        {
            label: t('components.StepTable.headers.order'),
            id: 'order',
        },
        {
            label: t('components.StepTable.headers.name'),
            id: 'name',
        },
        {
            label: t('components.StepTable.headers.type'),
            id: 'type',
        },
        {
            label: t('components.StepTable.headers.showDashboard'),
            id: 'showDashboard',
        },
        {
            label: t('components.StepTable.headers.timeLimit'),
            id: 'timeLimit',
        },
        {
            label: t('components.Table.headers.lastModifiedBy'),
            id: 'lastModifiedBy',
        },
        {
            label: t('components.Table.headers.action'),
            id: 'action',
        },
        {
            label: t('components.Table.headers.lastModifiedDate'),
            id: 'lastModifiedDate',
            sortable: true,
        },
    ];

    const showHistoryHandler = (index: number) => {
        setQueryHistory(items[index].id);
        dispatch(cleanStepHistory());
        dispatch(getStepHistoryAsync({ page, pageSize, query: String(items[index].id) }));
        openModal();
    };

    const removeStepHandler = (index: number) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette étape?')) {
            dispatch(deleteStepAsync({ step: items[index], index }));
        }
    };

    const setActiveIndexHandler = (index: number) => {
        setStepOngoing(items[index]);
        setActiveIndex(index);
    };

    const moveStepHandler = (moveStepInputs: MoveStepInput[]) => {
        dispatch(moveStepAsync({ trajectoryId, moveStepInputs: moveStepInputs }));
        dispatch(getStepAsync({ page, pageSize, query, orderBy, order }));
    };

    const readOnlyRow = (item: StepType, index: number) => ({
        ...item,
        showDashboard: t(`components.StepTable.showDashboard.${item.showDashboard}`),
        indicator: t(`components.StepTable.indicator.${stepIndicator(item)}`),
        controls: activeIndex ? (
            ''
        ) : (
            <>
                {tableEditionState === 'normal' && (
                    <TextButton onClick={() => showHistoryHandler(index)}>
                        {t('components.Table.history')}
                        <IconSuffix icon={<HistoryIcon size="16" />} />
                    </TextButton>
                )}
                {user && user.role === 'admin' && (
                    <>
                        {tableEditionState === 'normal' && (
                            <>
                                <TextButton onClick={() => setActiveIndexHandler(index)}>
                                    {t('components.Table.edit')}
                                    <IconSuffix icon={<Edit size="16" />} />
                                </TextButton>
                                <TextButton onClick={() => removeStepHandler(index)}>
                                    {t('components.Table.remove')}
                                    <IconSuffix icon={<RemoveIcon size="16" />} />
                                </TextButton>
                            </>
                        )}
                        {tableEditionState === 'order' && (
                            <Container style={{ padding: 0 }}>
                                <TextButton
                                    disabled={index === 0}
                                    style={{
                                        color: index === 0 ? 'grey' : theme.colors.primary.main,
                                        minHeight: '12px',
                                    }}
                                    onClick={() =>
                                        moveStepHandler([
                                            {
                                                id: item.id,
                                                oldOrder: item.order,
                                                newOrder: items[index - 1].order,
                                            },
                                            {
                                                id: items[index - 1].id,
                                                oldOrder: items[index - 1].order,
                                                newOrder: item.order,
                                            },
                                        ])
                                    }
                                >
                                    <IconSuffix icon={<MoveUpArrowIcon size="24" />} />
                                </TextButton>
                                <TextButton
                                    disabled={index === items.length - 1}
                                    style={{
                                        color:
                                            index === items.length - 1
                                                ? 'grey'
                                                : theme.colors.primary.main,
                                        minHeight: '12px',
                                    }}
                                    onClick={() =>
                                        moveStepHandler([
                                            {
                                                id: item.id,
                                                oldOrder: item.order,
                                                newOrder: items[index + 1].order,
                                            },
                                            {
                                                id: items[index + 1].id,
                                                oldOrder: items[index + 1].order,
                                                newOrder: item.order,
                                            },
                                        ])
                                    }
                                >
                                    <IconSuffix icon={<MoveDownArrowIcon size="24" />} />
                                </TextButton>
                            </Container>
                        )}
                    </>
                )}
            </>
        ),
    });

    const readOnlyHistoryRow = (item: StepTypeHistory, _index: number) => ({
        ...item,
        showDashboard: t(`components.StepTable.showDashboard.${item.showDashboard}`),
        lastModifiedBy:
            item.lastModifiedBy === 'system'
                ? t(`components.Table.modifiedBySystem`)
                : item.lastModifiedBy,
        action: t(`components.Table.actions.${action(item)}`),
    });

    const stepTimeLimitValidator = (value: string): boolean => value === '';

    const stepTimeLimitHandler = (value: string, _index: number) => {
        if (value === null || value === '') {
            stepTimeLimitErrorSetter(true);
        }
        const step = {
            ...stepOngoing,
            timeLimit: Number(value.trim()),
        };
        stepTimeLimitErrorSetter(stepTimeLimitValidator(value));
        setStepOngoing(step);
    };

    const nameValidator = (value: string): boolean => value === '';

    const nameHandler = (value: string, _index: number) => {
        const step = {
            ...stepOngoing,
            name: value.trim(),
        };
        nameErrorSetter(nameValidator(value.trim()));
        setStepOngoing(step);
    };

    const typeValidator = (value: string): boolean => value === '';

    const typeHandler = (value: string, _index: number) => {
        const step = {
            ...stepOngoing,
            type: value,
        };
        typeErrorSetter(typeValidator(value));
        setStepOngoing(step);
    };

    const showDashboardValidator = (value: string): boolean =>
        value === '' || value === 'undefined';

    const showDashboardHandler = (value: string, _index: number) => {
        const step = {
            ...stepOngoing,
            showDashboard: value === 'true',
        };
        showDashboardErrorSetter(showDashboardValidator(value));
        setStepOngoing(step);
    };

    const indicatorValidator = (value: string): boolean => value === '';

    const indicatorHandler = (value: string, _index: number) => {
        const step = {
            ...stepOngoing,
            indicator: value,
        };
        indicatorErrorSetter(indicatorValidator(value));
        setStepOngoing(step);
    };

    const cancelAddHandler = (index: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler l'ajout?")) {
            dispatch(removeStep(index));
            setActiveIndex(null);
            dispatch(getStepAsync({ page, pageSize, query, orderBy, order }));
        }
    };

    const cancelHandler = () => {
        if (window.confirm('Êtes-vous sûr de vouloir annuler la modification?')) {
            setActiveIndex(null);
        }
    };

    const validateStep = () => {
        nameErrorSetter(nameValidator(stepOngoing.name || ''));
        typeErrorSetter(typeValidator(stepOngoing.type || ''));
        showDashboardErrorSetter(showDashboardValidator(String(stepOngoing.showDashboard)));
        indicatorErrorSetter(indicatorValidator(stepOngoing.indicator));
        stepTimeLimitErrorSetter(indicatorValidator(stepOngoing.indicator));
        return (
            nameValidator(stepOngoing.name) ||
            typeValidator(stepOngoing.type) ||
            showDashboardValidator(String(stepOngoing.showDashboard)) ||
            indicatorValidator(stepOngoing.indicator)
        );
    };

    const putHandler = (index: number) => {
        if (validateStep()) {
            return;
        }

        if (stepOngoing.type !== items[index].type) {
            if (
                !window.confirm(
                    "Êtes-vous sûr de vouloir modifier le type de l'étape ? Vous risquez de perdre des données.",
                )
            ) {
                setActiveIndex(null);
                return;
            }
        }

        dispatch(putStepAsync({ step: stepOngoing, index }));
    };

    const postHandler = (index: number) => {
        if (validateStep()) {
            return;
        }
        const step = {
            ...stepOngoing,
            trajectoryId,
        };

        dispatch(postStepAsync({ step, index }));
    };

    const editableRow = (item: StepType, index: number) => ({
        id: item.id,
        order: stepOngoing.order || t('components.StepTable.values.order.last'),
        name: (
            <TextField
                label={t('components.StepTable.headers.name')}
                name="name"
                onChange={(e: React.FormEvent) =>
                    nameHandler((e.target as HTMLInputElement).value, index)
                }
                value={stepOngoing.name || ''}
                hasError={nameError}
                assistiveText={nameError ? 'Obligatoire' : ''}
                required
            />
        ),
        type: (
            <Select
                name="type"
                options={types}
                label={t('components.StepTable.headers.type')}
                value={stepOngoing.type || ''}
                onChange={(e: React.FormEvent) =>
                    typeHandler((e.target as HTMLInputElement).value, index)
                }
                variant="outlined"
                hasError={typeError}
                assistiveText={typeError ? 'Obligatoire' : ''}
                required
            />
        ),
        showDashboard: (
            <Select
                name="showDashboard"
                options={showDashboardOptions}
                label={t('components.StepTable.headers.showDashboard')}
                value={
                    stepOngoing.showDashboard !== undefined ? String(stepOngoing.showDashboard) : ''
                }
                onChange={(e: React.FormEvent) =>
                    showDashboardHandler((e.target as HTMLInputElement).value, index)
                }
                variant="outlined"
                hasError={showDashboardError}
                assistiveText={showDashboardError ? 'Obligatoire' : ''}
                required
            />
        ),
        indicator: (
            <Select
                name="indicator"
                options={indicators}
                label={t('components.StepTable.headers.indicator')}
                value={stepOngoing.indicator !== undefined ? String(stepOngoing.indicator) : ''}
                onChange={(e: React.FormEvent) =>
                    indicatorHandler((e.target as HTMLInputElement).value, index)
                }
                variant="outlined"
                hasError={indicatorError}
                assistiveText={indicatorError ? 'Obligatoire' : ''}
                required
            />
        ),
        timeLimit: (
            <TextField
                label={t('components.StepTable.headers.timeLimit')}
                name="timeLimit"
                type="number"
                inputProps={{ min: '0' }}
                onChange={(e: React.FormEvent) =>
                    stepTimeLimitHandler((e.target as HTMLInputElement).value, index)
                }
                value={stepOngoing.timeLimit || 0}
                hasError={stepTimeLimitError}
                assistiveText={stepTimeLimitError ? 'Obligatoire' : ''}
                required
            />
        ),
        controls:
            item.id === undefined ? (
                <>
                    <Button onClick={() => postHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelAddHandler(index)}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ) : (
                <>
                    <Button onClick={() => putHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelHandler()}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ),
    });

    const preparedRows = (steps: StepType[]) =>
        steps.map((item: StepType, index: number) => {
            if (index === activeIndex) {
                return editableRow(item, index);
            }

            return readOnlyRow(item, index);
        });

    const preparedHistoryRows = (stepHistories: StepTypeHistory[]) =>
        stepHistories.map((item: StepTypeHistory, index: number) =>
            readOnlyHistoryRow(item, index),
        );

    const prev = () => {
        if (page > 0) {
            page = page - 1;
            dispatch(changeToAmount(page));
        }
    };

    const next = () => {
        if (page < totalPage) {
            page = page + 1;
            dispatch(changeToAmount(page));
        }
    };

    const prevHistory = () => {
        if (pageHistory > 0) {
            pageHistory = pageHistory - 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getStepHistoryRequest();
    };

    const nextHistory = () => {
        if (pageHistory < totalPageHistory) {
            pageHistory = pageHistory + 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getStepHistoryRequest();
    };

    const pageNumberHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPage && newPageNumber > 0) {
            page = newPageNumber;
            dispatch(changeToAmount(page));
        }
    };

    const pageNumberHistoryHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPageHistory && newPageNumber > 0) {
            pageHistory = newPageNumber;
        }
        dispatch(changeToAmountHistory(newPageNumber));
        getStepHistoryRequest();
    };

    const addMoreHandler = () => {
        dispatch(addStep());
        setStepOngoing({} as StepType);
        setActiveIndex(0);
        setTableEditionState('normal');
    };

    const orderHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderBy(by);
        setOrder(sortOrder);
        page = 1;
        dispatch(changeToAmount(page));
    };

    const orderHistoryHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderByHistory(by);
        setOrderHistory(sortOrder);
        pageHistory = 0;
        dispatch(changeToAmount(pageHistory));
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                <Title>{t('pages.Step.history.title')}</Title>
                <TableRefiner>
                    <Table
                        headers={headersHistory}
                        rows={preparedHistoryRows(itemsHistory)}
                        sortOptions={{ sortBy: orderByHistory, sortOrder: orderHistory }}
                        onSort={(currentSortOptions) => orderHistoryHandler(currentSortOptions)}
                    />
                    {totalHistory > pageSize && (
                        <Pagination>
                            <Prev onClick={prevHistory}>{t('components.Table.prev')}</Prev>
                            <InputPagination
                                value={pageHistory}
                                onChange={(e: React.FormEvent) =>
                                    pageNumberHistoryHandler((e.target as HTMLInputElement).value)
                                }
                            />{' '}
                            / {totalPageHistory}
                            <Next onClick={nextHistory}>{t('components.Table.next')}</Next>
                        </Pagination>
                    )}
                </TableRefiner>
            </Modal>
            <Title>
                {t('pages.Step.title')}
                <ControlWrapper>
                    {user && user.role === 'admin' && (
                        <>
                            <Button
                                disabled={activeIndex !== null}
                                style={{
                                    backgroundColor:
                                        activeIndex !== null ? 'grey' : theme.colors.primary.main,
                                }}
                                onClick={() =>
                                    setTableEditionState(
                                        tableEditionState === 'normal' ? 'order' : 'normal',
                                    )
                                }
                            >
                                {t(
                                    `components.StepTable.mode.${
                                        tableEditionState === 'normal' ? 'order' : 'normal'
                                    }`,
                                )}
                            </Button>
                            <AddIcon size="16" onClick={addMoreHandler} />
                        </>
                    )}
                </ControlWrapper>
            </Title>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={preparedRows(items)}
                    sortOptions={{ sortBy: orderBy, sortOrder: order }}
                    onSort={(currentSortOptions) => orderHandler(currentSortOptions)}
                />
                {total > pageSize && (
                    <Pagination>
                        {page > 1 && <Prev onClick={prev}>{t('components.Table.prev')}</Prev>}
                        <InputPagination
                            value={page}
                            type="number"
                            onChange={(e: React.FormEvent) =>
                                pageNumberHandler((e.target as HTMLInputElement).value)
                            }
                        />{' '}
                        / {totalPage}
                        {page < totalPage && (
                            <Next onClick={next}>{t('components.Table.next')}</Next>
                        )}
                    </Pagination>
                )}
            </TableRefiner>
        </>
    );
};
