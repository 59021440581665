import React from 'react';
import styled from 'styled-components/macro';
import { CircularLoader } from '@osedea/reactor';

const Container = styled.div({
    position: 'fixed',
    zIndex: 99,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    width: '100vw',
    height: '100vh',

    background: 'white',
});

const Text = styled.div({
    margin: 24,
});

interface IProps {
    text?: string;
}

export const FullViewLoader: React.FunctionComponent<IProps> = ({ text }: IProps) => {
    const textElement = text ? <Text>{text}</Text> : null;

    return (
        <Container>
            <CircularLoader />
            {textElement}
        </Container>
    );
};
