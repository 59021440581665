import type { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { TrajectoryType, TrajectoryTypeHistory, UsersTrajectoryType } from 'types';
import request from 'utils/request';

const pageSize = 10;

export type TrajectorySliceType = {
    trajectory: TrajectoryType[];
    error: string;
    total: number;
    page: number;
    query: string;
    trajectoryHistory: TrajectoryTypeHistory[];
    errorHistory: string;
    totalHistory: number;
    pageHistory: number;
    postSuccessful: boolean;
    putSuccessful: boolean;
};

export const initialState: TrajectorySliceType = {
    trajectory: [],
    error: '',
    total: 0,
    page: 1,
    query: '',
    trajectoryHistory: [],
    errorHistory: '',
    totalHistory: 0,
    pageHistory: 1,
    postSuccessful: false,
    putSuccessful: false,
};

// Requests

export const getTrajectoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: TrajectoryType[]; total: number; next: string | null }>(`/trajectory`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const postTrajectoryRequest = (trajectory: TrajectoryType) =>
    request<{ data: TrajectoryType }>(`/trajectory`, {
        method: 'POST',
        data: trajectory,
    });

export const putTrajectoryRequest = (trajectory: TrajectoryType) =>
    request<{ data: TrajectoryType }>(`/trajectory/${trajectory.id}`, {
        method: 'PUT',
        data: trajectory,
    });

export const getTrajectoryHistoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: TrajectoryTypeHistory[]; total: number }>(`/audit-trajectory`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const deleteTrajectoryRequest = (trajectory: TrajectoryType) =>
    request(`/trajectory/${trajectory.id}`, {
        method: 'DELETE',
    });

export const createEventRequest = (id: number) =>
    request(`/trajectory/event`, {
        method: 'POST',
        data: {
            id,
        },
    });

export const postUsersTrajectoryRequest = (usersTrajectory: UsersTrajectoryType) =>
    request<{ data: { data: UsersTrajectoryType } }>(`/users-trajectory`, {
        method: 'POST',
        data: usersTrajectory,
    });

// Thunks

export const getTrajectoryAsync = createAsyncThunk<
    { data: TrajectoryType[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('trajectory', async ({ page, query, orderBy, order }) => {
    const response = await getTrajectoryRequest(page, query, orderBy, order);
    return response.data;
});

export const postTrajectoryAsync = createAsyncThunk<
    { value: TrajectoryType; index: number },
    { trajectory: TrajectoryType; index: number }
>('post/trajectory', async ({ trajectory, index }) => {
    const response = await postTrajectoryRequest(trajectory);
    const usersTrajectory = { trajectoryId: response.data.data.id } as UsersTrajectoryType;
    await postUsersTrajectoryRequest(usersTrajectory);
    return { value: response.data.data, index };
});

export const putTrajectoryAsync = createAsyncThunk<
    { value: TrajectoryType; index: number },
    { trajectory: TrajectoryType; index: number }
>('put/trajectory', async ({ trajectory, index }) => {
    const response = await putTrajectoryRequest(trajectory);
    return { value: response.data.data, index };
});

export const deleteTrajectoryAsync = createAsyncThunk<
    { index: number },
    { trajectory: TrajectoryType; index: number }
>('delete/trajectory', async ({ trajectory, index }) => {
    await deleteTrajectoryRequest(trajectory)
        // hotfix to have a message when a trajectory is deleted with data
        // Cf. https://github.com/Osedea/chum_parcours-patient-frontend/pull/228
        .catch((error) => {
            if (error.status === 500) {
                throw new Error(
                    'Erreur lors de la tentative de suppression de ce guichet, il y a probablement des données associées.',
                );
            }
        });
    return { index };
});

export const getTrajectoryHistoryAsync = createAsyncThunk<
    { data: TrajectoryTypeHistory[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('trajectoryHistory', async ({ page, query, orderBy, order }) => {
    const response = await getTrajectoryHistoryRequest(page, query, orderBy, order);
    return response.data;
});

export const createEventAsync = createAsyncThunk<void, { id: number }>(
    'createEventAsync',
    async ({ id }) => {
        await createEventRequest(id);
    },
);

export const logoutTrajectory = () => (dispatch: Dispatch) => {
    dispatch(cleanTrajectory());
    dispatch(cleanTrajectoryHistory());
};

/**
 * get all trajectories.
 */
export const getAllTrajectoryAsync = () => async (dispatch: Dispatch) => {
    let page = 1;
    let response;
    dispatch(cleanTrajectory());
    do {
        response = await getTrajectoryRequest(page);
        dispatch(addAllTrajectory(response.data.data));
        page++;
    } while (response.data.next !== null);
    return response.data;
};

export const slice = createSlice({
    name: 'trajectory',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanTrajectory(state) {
            state.trajectory = [];
            state.error = '';
        },
        addTrajectory(state) {
            state.trajectory = [{} as TrajectoryType];
        },
        removeTrajectory(state, action: PayloadAction<number>) {
            state.trajectory = state.trajectory.slice(0, action.payload);
        },
        changeToAmount(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        cleanTrajectoryHistory(state) {
            state.trajectoryHistory = [];
            state.error = '';
        },
        changeToAmountHistory(state, action: PayloadAction<number>) {
            state.pageHistory = action.payload;
        },
        changeQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
            state.page = 1;
        },
        addAllTrajectory(state, action: PayloadAction<TrajectoryType[]>) {
            state.trajectory = [...state.trajectory, ...action.payload];
            state.error = '';
        },
        reset: (_state) => initialState,
        resetPostSuccessful(state) {
            state.postSuccessful = !state.postSuccessful;
        },
        resetPutSuccessful(state) {
            state.putSuccessful = !state.putSuccessful;
        },
    },
    extraReducers(builder) {
        builder.addCase(getTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.trajectory = payload.data;
            state.total = payload.total;
            state.error = '';
        });
        builder.addCase(getTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(postTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.trajectory[payload.index] = payload.value;
            state.postSuccessful = true;
            state.error = '';
        });
        builder.addCase(postTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(putTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.trajectory[payload.index] = payload.value;
            state.putSuccessful = true;
            state.error = '';
        });
        builder.addCase(putTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(deleteTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.trajectory = state.trajectory.filter((_item, index) => index !== payload.index);
            state.error = '';
        });
        builder.addCase(deleteTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(getTrajectoryHistoryAsync.fulfilled, (state, { payload }) => {
            state.trajectoryHistory = payload.data;
            state.totalHistory = payload.total;
            state.error = '';
        });
        builder.addCase(getTrajectoryHistoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const {
    resetError,
    changeToAmount,
    changeToAmountHistory,
    cleanTrajectoryHistory,
    changeQuery,
    addTrajectory,
    removeTrajectory,
    cleanTrajectory,
    addAllTrajectory,
    reset,
    resetPostSuccessful,
    resetPutSuccessful,
} = slice.actions;

export default slice.reducer;

// Selectors

export const selectTrajectory = (state: RootState) => state.trajectory.trajectory;
export const selectTotal = (state: RootState) => state.trajectory.total;
export const selectError = (state: RootState) => state.trajectory.error;
export const selectPage = (state: RootState) => state.trajectory.page;
export const selectQuery = (state: RootState) => state.trajectory.query;
export const selectTrajectoryHistory = (state: RootState) => state.trajectory.trajectoryHistory;
export const selectTotalHistory = (state: RootState) => state.trajectory.totalHistory;
export const selectPageHistory = (state: RootState) => state.trajectory.pageHistory;
export const selectPostSuccessful = (state: RootState) => state.trajectory.postSuccessful;
export const selectPutSuccessful = (state: RootState) => state.trajectory.putSuccessful;
