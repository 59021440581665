import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, Button } from '@osedea/reactor';
import { useParams, useMatch, Link, useNavigate } from 'react-router-dom';

import { CenteredContainer } from 'styles/common';
import { updatePassword } from 'slices/authenticationSlice';
import { LoginCard, Title } from './styles';
import type { RootState } from 'store';
import { useAppDispatch } from 'hooks/reduxHooks';

interface IProps {}

const PasswordReset = (_props: IProps) => {
    const { t } = useTranslation();
    const { token } = useParams<{ token: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const match = useMatch('/creation-compte/:token');
    const isAccountSetup = !!match;
    const translationPageKey = isAccountSetup ? 'AccountSetup' : 'PasswordReset';

    const [password, setPassword] = useState<string>('');
    const [isSubmitted, setSubmitted] = useState<boolean>(false);

    const error = useSelector((state: RootState) => state.authentication.error, shallowEqual);

    const validatePassword = () =>
        password.trim() &&
        Boolean(/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+.,;:])(?=.*\d).{8,}$/.exec(password));

    const authHandler = () => {
        if (!token) {
            return;
        }

        setSubmitted(true);
        dispatch(updatePassword({ password, token }));
    };

    useEffect(() => {
        if (isSubmitted && !error) {
            navigate('/');
        }
    }, [isSubmitted, error, history]);

    return (
        <>
            <Helmet>
                <title>{t(`pages.${translationPageKey}.title`)}</title>
            </Helmet>
            <CenteredContainer>
                <Title>{t(`pages.${translationPageKey}.title`)}</Title>
                <LoginCard>
                    <div>{t('pages.PasswordReset.explanation')}</div>
                    <TextField
                        name="password"
                        type="password"
                        label={t('pages.Login.password')}
                        onChange={(e: React.FormEvent) =>
                            setPassword((e.target as HTMLInputElement).value)
                        }
                        value={password}
                    />
                    <Button disabled={!validatePassword()} onClick={authHandler}>
                        {t(`pages.${translationPageKey}.submit`)}
                    </Button>
                    {isSubmitted && error ? t('general.error') : null}
                    {isSubmitted && !error ? (
                        <div>
                            {t(`pages.${translationPageKey}.updatedMessage`)}
                            <br />
                            <Link to="/">Retourner au login</Link>
                        </div>
                    ) : null}
                </LoginCard>
            </CenteredContainer>
        </>
    );
};

export default PasswordReset;
