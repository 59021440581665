import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container } from 'styles/common';
import { UsersTable } from 'components/UsersTable';

export const Users: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('pages.Users.title')}</title>
            </Helmet>
            <Container>
                <UsersTable />
            </Container>
        </>
    );
};
