import React from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';

import dayjs from 'dayjs';

import type {
    DashboardType,
    TrajectoryGlobalStatsType,
    TrajectoryStepStatsType,
    DelaiAverage,
} from 'types';
import { status } from 'utils/type.utils';

export interface IProps {
    dashboard: DashboardType | null;
    trajectoryGlobalStats: TrajectoryGlobalStatsType | null;
    trajectoryStatsSteps: TrajectoryStepStatsType[];
    dateTime: string;
}

export interface DashboardExcelType {
    name: DashboardType['name'];
    trajectoryGlobalStats: TrajectoryGlobalStatsType[];
}

type Datas = Array<Record<string, string>>;

export const ExportExcel: React.FunctionComponent<IProps> = ({
    dashboard,
    trajectoryGlobalStats,
    trajectoryStatsSteps,
    dateTime,
}) => {
    const { t } = useTranslation();

    const displayDelaiAverage = (delaiAverage: DelaiAverage): string => {
        const isNumber = Number(delaiAverage) === delaiAverage;
        if (!isNumber) {
            return String(delaiAverage);
        }

        const parsedDelaiAverage = Intl.NumberFormat('fr-CA').format(Number(delaiAverage));
        return parsedDelaiAverage;
    };

    const dashboardResume = () => {
        const dashboardName = dashboard?.name ?? '';
        const dashboardArray = [
            {
                [t(`pages.Dashboard.name`)]: t('pages.Dashboard.nbPatientsHorsDelai'),
                [dashboardName]: trajectoryGlobalStats
                    ? `${trajectoryGlobalStats.nbPatientsHorsDelai} patients`
                    : '-',
            },
            {
                [t(`pages.Dashboard.name`)]: t('pages.Dashboard.nbTotalPatients'),
                [dashboardName]: trajectoryGlobalStats
                    ? `${trajectoryGlobalStats.nbTotalPatients} patients`
                    : '-',
            },
            {
                [t(`pages.Dashboard.name`)]: `${t('pages.Dashboard.delaiAverage')} (jours)`,
                [dashboardName]: trajectoryGlobalStats
                    ? displayDelaiAverage(trajectoryGlobalStats.delaiAverage)
                    : '-',
            },
            {
                [t(`pages.Dashboard.name`)]: `${t('pages.Dashboard.targetDelay')} (jours)`,

                [dashboardName]: trajectoryGlobalStats ? trajectoryGlobalStats.targetDelay : '-',
            },
        ];

        return dashboardArray;
    };

    const dashboardSteps = () => {
        const delaysArray: Datas = [];

        trajectoryStatsSteps.forEach((trajectoryStatStep) => {
            delaysArray.push({
                [t(`pages.Dashboard.step`)]: trajectoryStatStep.name,
                [t(`pages.Dashboard.delayAverage`)]: displayDelaiAverage(
                    trajectoryStatStep.delaiAverage,
                ),
                [t(`pages.Dashboard.delayTarget`)]: trajectoryStatStep.timeLimit
                    ? `${trajectoryStatStep.timeLimit} jours`
                    : '0 jours',
                [t(`pages.Dashboard.nbPatientsHorsDelai`)]:
                    `${trajectoryStatStep.nbPatientsHorsDelai} patients`,
                [t(`pages.Dashboard.nbTotalPatients`)]:
                    `${trajectoryStatStep.nbTotalPatients} patients`,
            });
        });
        return delaysArray;
    };

    const getPatients = () => {
        const patientsRow: Datas = [];

        dashboard?.patients.forEach((patient) => {
            const { lastName, firstName, fileNumber, nam, trajectory, entries, comment } = patient;

            let allSteps = {};

            trajectoryStatsSteps.forEach((trajectoryStatStep, _index) => {
                const entryOfThatStep = entries?.find(
                    (singleEntry) => singleEntry.stepId === trajectoryStatStep.id,
                );
                let entryValue;
                if (trajectoryStatStep.type === 'date' && entryOfThatStep?.entryDate) {
                    entryValue = dayjs(entryOfThatStep.entryDate).format('YYYY/MM/DD');
                } else if (trajectoryStatStep.type === 'info' && entryOfThatStep?.entryInfo) {
                    entryValue = entryOfThatStep.entryInfo;
                } else {
                    entryValue = '--';
                }
                allSteps = {
                    ...allSteps,
                    [trajectoryStatStep.name]: entryValue,
                    [`${trajectoryStatStep.name} - commentaire`]: entryOfThatStep?.comment ?? '--',
                };
            });

            patientsRow.push({
                [t(`pages.Dashboard.lastName`)]: lastName,
                [t(`pages.Dashboard.firstName`)]: firstName,
                [t(`pages.Dashboard.fileNumber`)]: fileNumber,
                [t(`pages.Dashboard.nam`)]: nam,
                [t(`pages.Dashboard.status`)]: t(`general.status.${status(patient)}`),
                [t(`pages.Dashboard.trajectoryName`)]: trajectory?.name,
                [t(`pages.Dashboard.comment`)]: comment === '' ? '--' : comment,
                ...allSteps,
            });
        });

        return patientsRow;
    };

    const exportFile = () => {
        const fileName = `${dashboard?.name.toLowerCase().replace(/\s/g, '_')}_${dateTime}.xlsx`;
        const quickView = dashboardResume();
        const delaysBySteps = dashboardSteps();
        const patients = getPatients();

        const sheetQuickView: XLSX.WorkSheet = XLSX.utils.json_to_sheet(quickView);
        const sheetDelayBySteps: XLSX.WorkSheet = XLSX.utils.json_to_sheet(delaysBySteps);
        const sheetPatients: XLSX.WorkSheet = XLSX.utils.json_to_sheet(patients);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, sheetQuickView, `Coup d'œil`);
        XLSX.utils.book_append_sheet(wb, sheetDelayBySteps, `Délais par étape`);
        XLSX.utils.book_append_sheet(wb, sheetPatients, `Patients`);

        XLSX.writeFile(wb, fileName);
    };

    return (
        <button type="button" onClick={exportFile}>
            {t(`pages.Dashboard.downloadExcel`)}
        </button>
    );
};
