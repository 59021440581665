import type { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { UsersTrajectoryType, UsersTrajectoryTypeHistory } from 'types';
import request from 'utils/request';

const pageSize = 10;

export type UsersTrajectorySliceType = {
    usersTrajectory: UsersTrajectoryType[];
    error: string;
    total: number;
    page: number;
    query: string;
    usersTrajectoryHistory: UsersTrajectoryTypeHistory[];
    errorHistory: string;
    totalHistory: number;
    pageHistory: number;
    usersId: number;
};

export const initialState: UsersTrajectorySliceType = {
    usersTrajectory: [],
    error: '',
    total: 0,
    page: 1,
    query: '',
    usersTrajectoryHistory: [],
    errorHistory: '',
    totalHistory: 0,
    pageHistory: 1,
    usersId: -1,
};

// Requests

export const getUsersTrajectoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: UsersTrajectoryType[]; total: number; next: string | null }>(
        `/users-trajectory`,
        {
            method: 'GET',
            params: {
                pageSize,
                page: page - 1,
                query,
                orderBy,
                order,
            },
        },
    );

export const postUsersTrajectoryRequest = (usersTrajectory: UsersTrajectoryType) =>
    request<{ data: UsersTrajectoryType }>(`/users-trajectory`, {
        method: 'POST',
        data: usersTrajectory,
    });

export const putUsersTrajectoryRequest = (usersTrajectory: UsersTrajectoryType) =>
    request<{ data: UsersTrajectoryType }>(`/users-trajectory/${usersTrajectory.id}`, {
        method: 'PUT',
        data: usersTrajectory,
    });

export const getUsersTrajectoryHistoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: UsersTrajectoryTypeHistory[]; total: number }>(`/audit-users-trajectory`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const deleteUsersTrajectoryRequest = (usersTrajectory: UsersTrajectoryType) =>
    request(`/users-trajectory/${usersTrajectory.id}`, {
        method: 'DELETE',
    });

export const createEventRequest = (id: number) =>
    request(`/users-trajectory/event`, {
        method: 'POST',
        data: {
            id,
        },
    });

// Thunks

export const getUsersTrajectoryAsync = createAsyncThunk<
    { data: UsersTrajectoryType[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('usersTrajectory', async ({ page, query, orderBy, order }) => {
    const response = await getUsersTrajectoryRequest(page, query, orderBy, order);
    return response.data;
});

export const postUsersTrajectoryAsync = createAsyncThunk<
    { value: UsersTrajectoryType },
    { usersTrajectory: UsersTrajectoryType }
>('post/usersTrajectory', async ({ usersTrajectory }) => {
    const response = await postUsersTrajectoryRequest(usersTrajectory);
    return { value: response.data.data };
});

export const putUsersTrajectoryAsync = createAsyncThunk<
    { value: UsersTrajectoryType; index: number },
    { usersTrajectory: UsersTrajectoryType; index: number }
>('put/usersTrajectory', async ({ usersTrajectory, index }) => {
    const response = await putUsersTrajectoryRequest(usersTrajectory);
    return { value: response.data.data, index };
});

export const deleteUsersTrajectoryAsync = createAsyncThunk<
    { index: number },
    { usersTrajectory: UsersTrajectoryType; index: number }
>('delete/usersTrajectory', async ({ usersTrajectory, index }) => {
    await deleteUsersTrajectoryRequest(usersTrajectory);
    return { index };
});

export const getUsersTrajectoryHistoryAsync = createAsyncThunk<
    { data: UsersTrajectoryTypeHistory[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('usersTrajectoryHistory', async ({ page, query, orderBy, order }) => {
    const response = await getUsersTrajectoryHistoryRequest(page, query, orderBy, order);
    return response.data;
});

export const createEventAsync = createAsyncThunk<void, { id: number }>(
    'createEventAsync',
    async ({ id }) => {
        await createEventRequest(id);
    },
);

export const logoutUsersTrajectory = () => (dispatch: Dispatch) => {
    dispatch(cleanUsersTrajectory());
    dispatch(cleanUsersTrajectoryHistory());
};

export const getAllUsersTrajectoryAsync = (query?: string) => async (dispatch: Dispatch) => {
    let page = 1;
    let response;
    dispatch(cleanUsersTrajectory());
    do {
        response = await getUsersTrajectoryRequest(page, query);
        dispatch(addAllUsersTrajectory(response.data.data));
        page++;
    } while (response.data.next !== null);
    return response.data;
};

export const slice = createSlice({
    name: 'usersTrajectory',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanUsersTrajectory(state) {
            state.usersTrajectory = [];
            state.error = '';
        },
        addUsersTrajectory(state) {
            state.usersTrajectory.push({} as UsersTrajectoryType);
        },
        removeUsersTrajectory(state, action: PayloadAction<number>) {
            state.usersTrajectory = state.usersTrajectory.slice(0, action.payload);
        },
        changeToAmount(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        cleanUsersTrajectoryHistory(state) {
            state.usersTrajectoryHistory = [];
            state.error = '';
        },
        changeToAmountHistory(state, action: PayloadAction<number>) {
            state.pageHistory = action.payload;
        },
        changeQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
        },
        changeUsers(state, action: PayloadAction<number>) {
            state.usersId = action.payload;
        },
        addAllUsersTrajectory(state, action: PayloadAction<UsersTrajectoryType[]>) {
            state.usersTrajectory = [...state.usersTrajectory, ...action.payload];
            state.error = '';
        },
    },
    extraReducers(builder) {
        builder.addCase(getUsersTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.usersTrajectory = payload.data;
            state.total = payload.total;
            state.error = '';
        });
        builder.addCase(getUsersTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(postUsersTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.usersTrajectory.push(payload.value);
            state.error = '';
        });
        builder.addCase(postUsersTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(putUsersTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.usersTrajectory[payload.index] = payload.value;
            state.error = '';
        });
        builder.addCase(putUsersTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(deleteUsersTrajectoryAsync.fulfilled, (state, { payload }) => {
            state.usersTrajectory = state.usersTrajectory.filter(
                (_item, index) => index !== payload.index,
            );
            state.error = '';
        });
        builder.addCase(deleteUsersTrajectoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(getUsersTrajectoryHistoryAsync.fulfilled, (state, { payload }) => {
            state.usersTrajectoryHistory = payload.data;
            state.totalHistory = payload.total;
            state.error = '';
        });
        builder.addCase(getUsersTrajectoryHistoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const {
    resetError,
    changeToAmount,
    changeToAmountHistory,
    cleanUsersTrajectoryHistory,
    changeQuery,
    addUsersTrajectory,
    removeUsersTrajectory,
    cleanUsersTrajectory,
    changeUsers,
    addAllUsersTrajectory,
} = slice.actions;

export default slice.reducer;

// Selectors

export const selectUsersTrajectory = (state: RootState) => state.usersTrajectory.usersTrajectory;
export const selectTotal = (state: RootState) => state.usersTrajectory.total;
export const selectError = (state: RootState) => state.usersTrajectory.error;
export const selectPage = (state: RootState) => state.usersTrajectory.page;
export const selectQuery = (state: RootState) => state.usersTrajectory.query;
export const selectUsersTrajectoryHistory = (state: RootState) =>
    state.usersTrajectory.usersTrajectoryHistory;
export const selectTotalHistory = (state: RootState) => state.usersTrajectory.totalHistory;
export const selectPageHistory = (state: RootState) => state.usersTrajectory.pageHistory;
export const selectUsersId = (state: RootState) => state.usersTrajectory.usersId;
