import type { PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';
import type { PatientType, PatientTypeHistory } from 'types';
import request from 'utils/request';

const pageSize = 10;

export type PatientSliceType = {
    patient: PatientType[];
    error: string;
    total: number;
    page: number;
    query: string;
    patientHistory: PatientTypeHistory[];
    errorHistory: string;
    totalHistory: number;
    pageHistory: number;
    postSuccessful: boolean;
    putSuccessful: boolean;
};

export const initialState: PatientSliceType = {
    patient: [],
    error: '',
    total: 0,
    page: 1,
    query: '',
    patientHistory: [],
    errorHistory: '',
    totalHistory: 0,
    pageHistory: 1,
    postSuccessful: false,
    putSuccessful: false,
};

// Requests

export type ParamsType = {
    page: number;
    query?: string;
    trajectoryId?: number;
    orderBy?: string | number;
    order?: string;
    status?: string;
};

export const getPatientRequest = (params: ParamsType) =>
    request<{ data: PatientType[]; total: number; next: string }>(`/patient`, {
        method: 'GET',
        params: {
            ...params,
            page: params.page - 1,
        },
    });

export const postPatientRequest = (patient: PatientType) =>
    request<{ data: PatientType }>(`/patient`, {
        method: 'POST',
        data: patient,
    });

export const putPatientRequest = (patient: PatientType) =>
    request<{ data: PatientType }>(`/patient/${patient.id}`, {
        method: 'PUT',
        data: patient,
    });

export const getPatientHistoryRequest = (
    page: number,
    query?: string,
    orderBy?: string | number,
    order?: string,
) =>
    request<{ data: PatientTypeHistory[]; total: number }>(`/audit-patient`, {
        method: 'GET',
        params: {
            pageSize,
            page: page - 1,
            query,
            orderBy,
            order,
        },
    });

export const deletePatientRequest = (patient: PatientType) =>
    request(`/patient/${patient.id}`, {
        method: 'DELETE',
    });

export const createEventRequest = (id: number) =>
    request(`/patient/event`, {
        method: 'POST',
        data: {
            id,
        },
    });

// Thunks

export const getPatientAsync = createAsyncThunk<
    { data: PatientType[]; total: number },
    {
        page: number;
        query?: string;
        orderBy?: string | number;
        order?: string;
        trajectoryId?: number;
        status?: string;
    }
>('patient', async ({ page, query, orderBy, order, trajectoryId, status }) => {
    const response = await getPatientRequest({
        page,
        query,
        orderBy,
        order,
        trajectoryId,
        status,
    });
    return response.data;
});

export const postPatientAsync = createAsyncThunk<
    { value: PatientType; index: number },
    { patient: PatientType; index: number }
>('post/patient', async ({ patient, index }) => {
    const response = await postPatientRequest(patient);
    return { value: response.data.data, index };
});

export const putPatientAsync = createAsyncThunk<
    { value: PatientType; index: number },
    { patient: PatientType; index: number }
>('put/patient', async ({ patient, index }) => {
    const response = await putPatientRequest(patient);
    return { value: response.data.data, index };
});

export const deletePatientAsync = createAsyncThunk<
    { index: number },
    { patient: PatientType; index: number }
>('delete/patient', async ({ patient, index }) => {
    await deletePatientRequest(patient);
    return { index };
});

export const getPatientHistoryAsync = createAsyncThunk<
    { data: PatientTypeHistory[]; total: number },
    { page: number; query?: string; orderBy?: string | number; order?: string }
>('patientHistory', async ({ page, query, orderBy, order }) => {
    const response = await getPatientHistoryRequest(page, query, orderBy, order);
    return response.data;
});

export const createEventAsync = createAsyncThunk<void, { id: number }>(
    'createEventAsync',
    async ({ id }) => {
        await createEventRequest(id);
    },
);

export const logoutPatient = () => (dispatch: Dispatch) => {
    dispatch(cleanPatient());
    dispatch(cleanPatientHistory());
};

export const getAllPatientsAsync = (params: ParamsType) => async (dispatch: Dispatch) => {
    let page = 1;
    let response;
    dispatch(cleanPatient());
    do {
        response = await getPatientRequest({ ...params, page });
        dispatch(addAllPatient(response.data.data));
        page++;
    } while (response.data.next !== null);
    return response.data;
};

export const slice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
        resetError(state) {
            state.error = '';
        },
        cleanPatient(state) {
            state.patient = [];
            state.error = '';
        },
        addPatient(state) {
            state.patient = [{} as PatientType];
        },
        removePatient(state, action: PayloadAction<number>) {
            state.patient = state.patient.slice(0, action.payload);
        },
        changeToAmount(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        cleanPatientHistory(state) {
            state.patientHistory = [];
            state.error = '';
        },
        changeToAmountHistory(state, action: PayloadAction<number>) {
            state.pageHistory = action.payload;
        },
        changeQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
            state.page = 1;
        },
        addAllPatient(state, action: PayloadAction<PatientType[]>) {
            state.patient = [...state.patient, ...action.payload];
            state.error = '';
        },
        reset: (_state) => initialState,
        resetPostSuccessful(state) {
            state.postSuccessful = !state.postSuccessful;
        },
        resetPutSuccessful(state) {
            state.putSuccessful = !state.putSuccessful;
        },
    },
    extraReducers(builder) {
        builder.addCase(getPatientAsync.fulfilled, (state, { payload }) => {
            state.patient = payload.data;
            state.total = payload.total;
            state.error = '';
        });
        builder.addCase(getPatientAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(postPatientAsync.fulfilled, (state, { payload }) => {
            state.patient[payload.index] = payload.value;
            state.postSuccessful = true;
            state.error = '';
        });
        builder.addCase(postPatientAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(putPatientAsync.fulfilled, (state, { payload }) => {
            state.patient[payload.index] = payload.value;
            state.putSuccessful = true;
            state.error = '';
        });
        builder.addCase(putPatientAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(deletePatientAsync.fulfilled, (state, { payload }) => {
            state.patient = state.patient.filter((_item, index) => index !== payload.index);
            state.error = '';
        });
        builder.addCase(deletePatientAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
        builder.addCase(getPatientHistoryAsync.fulfilled, (state, { payload }) => {
            state.patientHistory = payload.data;
            state.totalHistory = payload.total;
            state.error = '';
        });
        builder.addCase(getPatientHistoryAsync.rejected, (state, action) => {
            state.error = action.error.message ?? 'oops, il y a eu un problème ';
        });
    },
});

export const {
    resetError,
    changeToAmount,
    changeToAmountHistory,
    cleanPatientHistory,
    changeQuery,
    addPatient,
    removePatient,
    cleanPatient,
    addAllPatient,
    reset,
    resetPostSuccessful,
    resetPutSuccessful,
} = slice.actions;

export default slice.reducer;

// Selectors

export const selectPatient = (state: RootState) => state.patient.patient;
export const selectTotal = (state: RootState) => state.patient.total;
export const selectError = (state: RootState) => state.patient.error;
export const selectPage = (state: RootState) => state.patient.page;
export const selectQuery = (state: RootState) => state.patient.query;
export const selectPatientHistory = (state: RootState) => state.patient.patientHistory;
export const selectTotalHistory = (state: RootState) => state.patient.totalHistory;
export const selectPageHistory = (state: RootState) => state.patient.pageHistory;
export const selectPostSuccessful = (state: RootState) => state.patient.postSuccessful;
export const selectPutSuccessful = (state: RootState) => state.patient.putSuccessful;
