import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const FixedContainer = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
`;

export const Navigation = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MainNavigation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    transition:
        transform 250ms,
        opacity 250ms;
    margin: 0 1rem;
`;
export const UserNavigation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    transition:
        transform 250ms,
        opacity 250ms;
    margin: 0 1rem;
`;

export const MainNavLink = styled(NavLink)`
    min-width: 179px;
    min-height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-decoration: none;
    color: inherit;
    pointer-events: all;

    letter-spacing: 1px;

    &.active {
        background-color: #ebf3ff;
    }
    &:hover {
        opacity: 0.7;
    }
`;

export const MainNavButton = styled.a`
    min-width: 179px;
    min-height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-decoration: none;
    color: inherit;
    pointer-events: all;
    cursor: pointer;

    letter-spacing: 1px;

    &.active {
        background-color: #ebf3ff;
    }
    &:hover {
        opacity: 0.7;
    }
`;
