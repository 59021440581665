import type { Action } from '@reduxjs/toolkit';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import type { ThunkAction } from 'redux-thunk';
import { thunk } from 'redux-thunk';

import authenticationReducer from 'slices/authenticationSlice';
import usersReducer from 'slices/usersSlice';
import trajectoryReducer from 'slices/trajectorySlice';
import stepReducer from 'slices/stepSlice';
import patientReducer from 'slices/patientSlice';
import entryReducer from 'slices/entrySlice';
import usersTrajectoryReducer from 'slices/usersTrajectorySlice';
import dashboardReducer from 'slices/dashboardSlice';

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    users: usersReducer,
    trajectory: trajectoryReducer,
    step: stepReducer,
    patient: patientReducer,
    entry: entryReducer,
    usersTrajectory: usersTrajectoryReducer,
    dashboard: dashboardReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export type GetStateFn = typeof store.getState;

export default store;
