import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'dayjs/locale/fr';

import defaultFr from './locales/fr/default.json';
import defaultEn from './locales/en/default.json';
// import Backend from 'i18next-http-backend';

export const defaultNS = 'default';

export const resources = {
    en: { default: defaultEn },
    fr: { default: defaultFr },
};

export const supportedLanguageFr = 'fr';
export const supportedLanguageEn = 'en';

export const defaultLanguage = supportedLanguageFr;

export const supportedLanguages = [supportedLanguageEn, supportedLanguageFr];

i18n
    // load translation using http -> see /public/locales
    // .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        fallbackLng: defaultLanguage, // Do not set lng directly or else on page refresh it will always reset to en
        ns: ['default'],
        defaultNS,
        resources,
        supportedLngs: supportedLanguages,
        react: {
            transSupportBasicHtmlNodes: true,
        },
    });

export default i18n;
