import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container } from 'styles/common';
import { TrajectoryTable } from 'components/TrajectoryTable';

export const Trajectory: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('pages.Trajectory.title')}</title>
            </Helmet>
            <Container>
                <TrajectoryTable />
            </Container>
        </>
    );
};
