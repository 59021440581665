import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { SupervisorAccount } from '@styled-icons/material';
import { Time } from '@styled-icons/boxicons-regular';
import dayjs from 'dayjs';

import { status as statusFn } from 'utils/type.utils';
import {
    HeaderTwo,
    HeaderThree,
    BodyTwo,
    IconSuffix,
    Button,
    IDesignSystem,
    CircularProgress,
} from '@osedea/reactor';
import type { SelectOption } from '@osedea/reactor/dist/components/textFields/types';

import {
    selectDashboard,
    selectTrajectoryStats,
    selectTrajectoryStatsSteps,
    reset,
    getDashboardInfoV2Async,
    selectIsLoading,
} from 'slices/dashboardSlice';
import {
    selectTrajectory,
    getAllTrajectoryAsync,
    reset as resetTrajectory,
} from 'slices/trajectorySlice';
import { Container, Title, ControlWrapper, SelectWrapper } from 'styles/common';
import { ExportExcel } from 'components/ExportExcel';
import type { DelaiAverage } from 'types/index';

const PageTitle = styled(HeaderTwo)`
    font-size: 24px;
`;

const SectionHeader = styled(HeaderThree)`
    color: #000000;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 24px;
`;

const PageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0%;
    width: 100%;
`;

const RightGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-inline-start: 0px;
`;

const LeftSection = styled.div`
    flex-direction: column;
    flex: 1 0 0%;
`;

const RightSection = styled.div`
    flex-direction: column;
    flex: 2 0 0%;
`;

const Grid = styled.div`
    padding-inline-start: 0px;
    width: 100%;
`;

const Box = styled.div`
    background: #f6f8fa;
    max-width: 289px;
    margin: 0 24px 24px 0;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 100px;
`;

const SubBox = styled.div`
    background: #f6f8fa;
    max-width: 289px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 100px;
`;

const BoxAverage = styled.div`
    background: #f6f8fa;
    margin: 0 24px 24px 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1 1 100%;
    min-height: 100px;
    max-width: 250px;
`;

const InnerBox = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 50%;
`;

const BoxTitle = styled(BodyTwo)`
    font-size: 14px;
    width: fit-content;
`;

const BoxText = styled(HeaderTwo)`
    font-size: 24px;
    width: fit-content;
    margin-bottom: 8px;
`;

const IconSuffixStyled = styled(IconSuffix)`
    margin: 0px;
`;

const Select = styled.select`
    margin-right: 8px;
`;

const UnfilteredStatusWarningMessage = styled.div`
    font-size: small;
    max-width: 289px;
    margin: 0 24px 24px 0;
`;

const DashboardDataInputDescription = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 64px;
`;

const displayDelaiAverage = (delaiAverage: DelaiAverage): string => {
    const isNumber = Number(delaiAverage) === delaiAverage;
    if (!isNumber) {
        return String(delaiAverage);
    }

    const roundedDelaiAverage = Math.round(Number(delaiAverage));
    return String(roundedDelaiAverage);
};

/**
 * dashboard date format
 */
const dateFormat = 'YYYY-MM-DD';

export const Dashboard: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme() as IDesignSystem;

    // dashboard input states
    const [trajectoryId, setTrajectoryId] = useState<number>(-1);
    const [status, setStatus] = useState<string>('ongoing');
    const [beginDateState, setBeginDateState] = useState<'firstDate' | 'custom'>('firstDate');
    const [begin, setBegin] = useState<Date | null>(null);
    const today = new Date();
    // end date is set to be at the end of the month
    const [end, setEnd] = useState<Date>(new Date(today.getFullYear(), today.getMonth() + 1, 0));

    const availableTrajectories = useSelector(selectTrajectory);
    const rawDashboardData = useSelector(selectDashboard);

    // dashboard loading state controled in the global state
    const isLoading = useSelector(selectIsLoading);

    const [computationDataInputs, setComputationDataInputs] =
        useState<DashBoardContentProps | null>(null);

    const canComputeDashboardData =
        trajectoryId > -1 && !Number.isNaN(end.valueOf()) && (begin === null || begin < end);

    const haveInputschanged = () => {
        if (rawDashboardData === null) {
            return true;
        }
        if (computationDataInputs === null) {
            return true;
        } else {
            if (computationDataInputs.trajectoryId !== trajectoryId) {
                return true;
            }
            if (computationDataInputs.status !== status) {
                return true;
            }
            if (computationDataInputs.end !== dayjs(end).format(dateFormat)) {
                return true;
            }
            if (
                (begin !== null &&
                    computationDataInputs.begin !== dayjs(begin).format(dateFormat)) ||
                (begin === null && computationDataInputs.begin !== 'Invalid Date')
            ) {
                return true;
            }
        }

        return false;
    };

    const triggerDashboardDataComputation = () => {
        dispatch(reset());
        if (canComputeDashboardData) {
            dispatch(
                getDashboardInfoV2Async({
                    trajectoryId,
                    status: status === 'unfiltered' ? undefined : status,
                    begin,
                    end,
                }),
            );
            setComputationDataInputs({
                trajectoryId,
                status,
                begin: dayjs(begin).format(dateFormat),
                end: dayjs(end).format(dateFormat),
                computedAt: dayjs().format('YYYY-MM-DD - HH:mm:ss'),
            });
        }
    };

    useEffect(() => {
        dispatch(getAllTrajectoryAsync());
    }, []);

    useEffect(
        () => () => {
            dispatch(resetTrajectory());
            dispatch(reset());
        },
        [],
    );

    useEffect(() => {
        if (beginDateState === 'custom') {
            const today = new Date();
            setBegin(new Date(today.getFullYear(), today.getMonth(), 1));
        } else {
            setBegin(null);
        }
    }, [beginDateState]);

    const trajectoryIds = [
        {
            label: t('entity.parcours.selectTrajectory'),
            value: -1,
            disabled: true,
        },
        ...availableTrajectories.map((trajectory) => ({
            label: trajectory.name,
            value: trajectory.id,
        })),
    ] as SelectOption[];

    const statusOptions = [
        {
            label: t('entity.parcours.selectStatus'),
            value: 'choose',
            disabled: true,
        },
        {
            label: t('components.PatientTable.status.ongoing'),
            value: 'ongoing',
        },
        {
            label: t('components.PatientTable.status.completed'),
            value: 'completed',
        },
        {
            label: t('components.PatientTable.status.cancelled'),
            value: 'cancelled',
        },
        {
            label: t('components.PatientTable.status.unfiltered'),
            value: 'unfiltered',
        },
    ] as SelectOption[];

    return (
        <>
            <Helmet>
                <title>{t('pages.Dashboard.title')}</title>
            </Helmet>
            <Container>
                <Title>
                    <ControlWrapper>
                        <SelectWrapper>
                            <Select
                                name="trajectoryId"
                                onBlur={(e: React.FormEvent) =>
                                    setTrajectoryId(Number((e.target as HTMLInputElement).value))
                                }
                                onChange={(e: React.FormEvent) =>
                                    setTrajectoryId(Number((e.target as HTMLInputElement).value))
                                }
                                value={trajectoryId}
                            >
                                {trajectoryIds.map((item) => (
                                    <option
                                        key={item.value}
                                        value={item.value}
                                        disabled={item.disabled}
                                    >
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper>
                            <Select
                                name="status"
                                onBlur={(e: React.FormEvent) =>
                                    setStatus((e.target as HTMLInputElement).value)
                                }
                                onChange={(e: React.FormEvent) =>
                                    setStatus((e.target as HTMLInputElement).value)
                                }
                                value={status}
                            >
                                {statusOptions.map((item) => (
                                    <option
                                        key={item.value}
                                        value={item.value}
                                        disabled={item.disabled ?? false}
                                    >
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </SelectWrapper>
                        <SelectWrapper
                            style={{
                                wordBreak: 'normal',
                                marginRight: '8px',
                            }}
                        >
                            {t('pages.Dashboard.content.from')}
                        </SelectWrapper>
                        <SelectWrapper>
                            <Select
                                name="beginDateState"
                                onBlur={(event) =>
                                    setBeginDateState(event.target.value as 'firstDate' | 'custom')
                                }
                                onChange={(event) =>
                                    setBeginDateState(event.target.value as 'firstDate' | 'custom')
                                }
                                value={beginDateState}
                            >
                                <option key="firstDate" value="firstDate">
                                    {t('pages.Dashboard.startDate.firstDate')}
                                </option>
                                <option key="custom" value="custom">
                                    {t('pages.Dashboard.startDate.custom')}
                                </option>
                            </Select>

                            {beginDateState === 'custom' && (
                                <input
                                    style={{ marginRight: '8px' }}
                                    type="date"
                                    id="begin"
                                    value={begin === null ? '' : dayjs(begin).format(dateFormat)}
                                    onChange={(event) => {
                                        const dateParts = event.target.value.split('-');
                                        setBegin(
                                            new Date(
                                                Number(dateParts[0]),
                                                Number(dateParts[1]) - 1,
                                                Number(dateParts[2]),
                                            ),
                                        );
                                    }}
                                />
                            )}
                        </SelectWrapper>
                        <SelectWrapper
                            style={{
                                wordBreak: 'normal',
                                marginRight: '8px',
                            }}
                        >
                            {t('pages.Dashboard.content.to')}
                        </SelectWrapper>
                        <SelectWrapper>
                            <input
                                style={{ marginRight: '8px' }}
                                type="date"
                                id="end"
                                value={dayjs(end).format(dateFormat)}
                                onChange={(event) => {
                                    const dateParts = event.target.value.split('-');
                                    setEnd(
                                        new Date(
                                            Number(dateParts[0]),
                                            Number(dateParts[1]) - 1,
                                            Number(dateParts[2]),
                                        ),
                                    );
                                }}
                                required
                            />
                        </SelectWrapper>
                        <Button
                            disabled={!canComputeDashboardData || !haveInputschanged()}
                            style={{
                                backgroundColor:
                                    !canComputeDashboardData || !haveInputschanged()
                                        ? 'grey'
                                        : theme.colors.primary.main,
                            }}
                            onClick={triggerDashboardDataComputation}
                        >
                            {t('pages.Dashboard.computeStats')}
                        </Button>
                    </ControlWrapper>
                </Title>
                <Grid>
                    {computationDataInputs && (
                        <DashBoardContent {...computationDataInputs}></DashBoardContent>
                    )}
                </Grid>
                {isLoading && (
                    <LoaderContainer>
                        <CircularProgress variant="loading" />
                        <div>{t('pages.Dashboard.isLoading')}</div>
                    </LoaderContainer>
                )}
            </Container>
        </>
    );
};

type DashBoardContentProps = {
    trajectoryId: number;
    status: string;
    begin: string;
    end: string;
    computedAt: string;
};

const DashBoardContent: React.FunctionComponent<DashBoardContentProps> = ({
    status,
    begin,
    end,
    computedAt,
}) => {
    const { t } = useTranslation();

    const trajectoryGlobalStats = useSelector(selectTrajectoryStats);
    const dashboard = useSelector(selectDashboard);
    const trajectoryStatsSteps = useSelector(selectTrajectoryStatsSteps);
    const trajectoryStatsStepsToShowInDashboard = trajectoryStatsSteps.filter(
        (trajectoryStatStep) => trajectoryStatStep.showDashboard,
    );

    const translatedStatus = t(`components.PatientTable.status.${statusFn({ status })}`);
    return (
        dashboard &&
        trajectoryGlobalStats && (
            <>
                <PageTitle>{dashboard.name}</PageTitle>
                <DashboardDataInputDescription>
                    <div>
                        {`${translatedStatus} ${t('pages.Dashboard.content.from')} ${
                            begin === 'Invalid Date'
                                ? t('pages.Dashboard.startDate.firstDate')
                                : begin
                        } ${t('pages.Dashboard.content.to')} ${end}  ${t(
                            'pages.Dashboard.content.computedAt',
                        )}  ${computedAt}`}
                    </div>

                    {dashboard?.name && trajectoryStatsSteps.length > 0 && (
                        <div>
                            <ExportExcel
                                dashboard={dashboard}
                                trajectoryGlobalStats={trajectoryGlobalStats}
                                trajectoryStatsSteps={trajectoryStatsSteps}
                                dateTime={dayjs().format('YYYY-MM-DD_HH-mm-ss')}
                            />
                        </div>
                    )}
                </DashboardDataInputDescription>
                <PageContainer>
                    <LeftSection>
                        <SectionHeader>Aperçu</SectionHeader>
                        {status === 'unfiltered' && (
                            <UnfilteredStatusWarningMessage>
                                {t('pages.Dashboard.unfilteredStatusWarningMessage')}
                            </UnfilteredStatusWarningMessage>
                        )}
                        <Box>
                            <IconSuffix icon={<SupervisorAccount size="68" />} />
                            <InnerBox>
                                <BoxTitle># patients hors délai</BoxTitle>
                                <BoxText>{trajectoryGlobalStats.nbPatientsHorsDelai}</BoxText>
                                <BoxTitle># patients chiffrés</BoxTitle>
                                <BoxText>{trajectoryGlobalStats.nbTotalPatients}</BoxText>
                            </InnerBox>
                        </Box>
                        <Box>
                            <IconSuffix icon={<Time size="68" />} />
                            <InnerBox>
                                <BoxTitle>Délai moyen (jours)</BoxTitle>
                                <BoxText>
                                    {displayDelaiAverage(trajectoryGlobalStats.delaiAverage)}
                                </BoxText>
                                <BoxTitle>Délai cible (jours)</BoxTitle>
                                <BoxText>{String(trajectoryGlobalStats.targetDelay)}</BoxText>
                            </InnerBox>
                        </Box>
                    </LeftSection>
                    <RightSection>
                        <SectionHeader>Délais par examen</SectionHeader>
                        <RightGrid>
                            {trajectoryStatsStepsToShowInDashboard.length > 0 &&
                                trajectoryStatsStepsToShowInDashboard.map((trajectoryStatsStep) => (
                                    <BoxAverage key={`info-step-${trajectoryStatsStep.id}`}>
                                        <div key={`info-step-div-${trajectoryStatsStep.id}`}>
                                            {trajectoryStatsStep.name
                                                .split(' - ')
                                                .map((item: string) => (
                                                    <BoxTitle
                                                        key={`info-step-div-box-title-${trajectoryStatsStep.id}-${item}`}
                                                    >
                                                        {item}
                                                    </BoxTitle>
                                                ))}
                                        </div>
                                        <SubBox>
                                            <IconSuffixStyled
                                                icon={<SupervisorAccount size="68" />}
                                            />
                                            <InnerBox>
                                                <BoxTitle># patients hors délai</BoxTitle>
                                                <BoxText>
                                                    {trajectoryStatsStep.nbPatientsHorsDelai}
                                                </BoxText>
                                                <BoxTitle># patients chiffrés</BoxTitle>
                                                <BoxText>
                                                    {trajectoryStatsStep.nbTotalPatients}
                                                </BoxText>
                                            </InnerBox>
                                        </SubBox>
                                        <SubBox>
                                            <IconSuffixStyled icon={<Time size="68" />} />
                                            <InnerBox>
                                                <BoxTitle>Délai moyen (jours)</BoxTitle>
                                                <BoxText>
                                                    {displayDelaiAverage(
                                                        trajectoryStatsStep.delaiAverage,
                                                    )}
                                                </BoxText>
                                                <BoxTitle>Délai cible (jours)</BoxTitle>
                                                <BoxText>
                                                    {String(trajectoryStatsStep.timeLimit)}
                                                </BoxText>
                                            </InnerBox>
                                        </SubBox>
                                    </BoxAverage>
                                ))}
                        </RightGrid>
                    </RightSection>
                </PageContainer>
            </>
        )
    );
};
