import styled from 'styled-components';
import { TimesCircle } from '@styled-icons/fa-solid';
import { Search, History } from '@styled-icons/boxicons-regular';
import { AddCircleOutline, RemoveCircleOutline } from '@styled-icons/material';
import { ArrowDown, ArrowUp } from 'styled-icons/evaicons-solid';

export const CenteredContainer = styled.div({
    display: 'flex',
    flex: '1 0 0%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0px 48px',
});

export const Container = styled.div({
    display: 'flex',
    flex: '1 0 0%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '30px 48px',
});

export const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
`;

export const Pagination = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    margin-top: 8px;
`;

export const TableRefiner = styled.div`
    width: 100%;
    overflow-x: auto;
    table {
        position: inherit;
    }
    tr {
        border-left: 1px solid #d6dadd;
        border-right: 1px solid #d6dadd;
    }
    th {
        vertical-align: top;
        word-break: normal;
        line-height: normal;
    }
    td {
        font-size: 14;
        vertical-align: top;
        word-break: normal;
        &:first-child ${Pagination} {
            justify-content: center !important;
        }
        & table td ${Pagination} {
            justify-content: center !important;
        }
        &:first-child ${Title} {
            justify-content: space-between;
        }
    }
`;

export const Prev = styled.a`
    margin-right: 8px;
    cursor: pointer;
    color: #0066ff;
`;

export const Next = styled.a`
    margin-left: 8px;
    cursor: pointer;
    color: #0066ff;
`;

export const InputPagination = styled.input`
    width: 40px;
    border-radius: 10px;
    margin-right: 4px;
`;

export const ControlWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const QueryInput = styled.input`
    border: 0px;
`;

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const QueryWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 0px;
    border-bottom: 1px solid black;
`;

export const TimesCircleIcon = styled(TimesCircle)`
    cursor: pointer;
`;

export const SearchIcon = styled(Search)`
    cursor: pointer;
`;

export const HistoryIcon = styled(History)`
    cursor: pointer;
`;

export const AddIcon = styled(AddCircleOutline)`
    cursor: pointer;
    margin: 8px;
    margin-left: 16px;
`;

export const RemoveIcon = styled(RemoveCircleOutline)`
    cursor: pointer;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
`;

export const MoveUpArrowIcon = styled(ArrowUp)`
    cursor: pointer;
`;

export const MoveDownArrowIcon = styled(ArrowDown)`
    cursor: pointer;
`;
