import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Edit,
    IconSuffix,
    Table,
    TextButton,
    TextField,
    TableExpandedContentContainer,
    TableExpandedContentToggler,
    Select,
} from '@osedea/reactor';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import dayjs from 'dayjs';

import type { SelectOption } from '@osedea/reactor/dist/components/textFields/types';
import type { SortOptions } from '@osedea/reactor/dist/compounds/table/types';

import {
    getPatientAsync,
    selectPatient,
    selectTotal,
    changeToAmount,
    selectPage,
    selectQuery,
    changeQuery,
    putPatientAsync,
    postPatientAsync,
    addPatient,
    removePatient,
    selectPatientHistory,
    getPatientHistoryAsync,
    cleanPatientHistory,
    selectTotalHistory,
    selectPageHistory,
    deletePatientAsync,
    changeToAmountHistory,
    reset,
    selectPostSuccessful,
    resetPostSuccessful,
    selectPutSuccessful,
    resetPutSuccessful,
} from 'slices/patientSlice';

import { changePatient, changeQuery as changeQueryPatient } from 'slices/entrySlice';

import { selectTrajectory, getAllTrajectoryAsync } from 'slices/trajectorySlice';

import {
    changeToAmount as changeToAmountStep,
    changeQuery as changeQueryStep,
} from 'slices/stepSlice';

import { selectUser } from 'slices/authenticationSlice';

import type { PatientType, PatientTypeHistory, UserType } from 'types';

import {
    TableRefiner,
    Pagination,
    Prev,
    Next,
    InputPagination,
    Title,
    QueryInput,
    QueryWrapper,
    ControlWrapper,
    SearchIcon,
    AddIcon,
    TimesCircleIcon,
    HistoryIcon,
    RemoveIcon,
    SelectWrapper,
} from 'styles/common';

import { EntryTable } from 'components/EntryTable';
import { PatientComment } from 'components/PatientComment';
import { USER_ROLES_HAVING_THE_RIGHT_TO_EDIT_PATIENTS } from 'constants/index';
import { action, status as statusFn } from 'utils/type.utils';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export const PatientTable: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pageSize = 10;

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [patientOngoing, setPatientOngoing] = React.useState({
        status: 'ongoing',
        comment: '',
    } as PatientType);

    const items = useSelector(selectPatient);
    const itemsHistory = useSelector(selectPatientHistory);
    const trajectory = useSelector(selectTrajectory);
    const query = useSelector(selectQuery);
    const [trajectoryId, setTrajectoryId] = useState<number>(-1);
    const [status, setStatus] = useState<string>('ongoing');
    const [queryHistory, setQueryHistory] = useState(0);
    const [expandedRowId, setExpandedRowId] = useState(-1);
    const [orderBy, setOrderBy] = useState<string | number>('id');
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    const [orderByHistory, setOrderByHistory] = useState<string | number>('id');
    const [orderHistory, setOrderHistory] = useState<'ASC' | 'DESC' | 'INACTIVE'>('ASC');
    let page = useSelector(selectPage);
    let pageHistory = useSelector(selectPageHistory);
    const postSuccessful = useSelector(selectPostSuccessful);
    const putSuccessful = useSelector(selectPutSuccessful);
    const total = useSelector(selectTotal);
    const totalPage = Math.ceil(total / pageSize);
    const totalHistory = useSelector(selectTotalHistory);
    const user = useSelector(selectUser);
    const totalPageHistory = Math.ceil(totalHistory / pageSize);
    const [lastNameError, lastNameErrorSetter] = useState(false);
    const [firstNameError, firstNameErrorSetter] = useState(false);
    const [fileNumberError, fileNumberErrorSetter] = useState(false);
    const [trajectoryIdError, trajectoryIdErrorSetter] = useState(false);
    const [namError, namErrorSetter] = useState(false);
    const [statusError, statusErrorSetter] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        dispatch(
            getPatientAsync({
                page,
                query: query !== '' ? query : undefined,
                orderBy,
                order,
                trajectoryId,
                status,
            }),
        );
        if (postSuccessful) {
            setActiveIndex(null);
            setOrderBy('id');
            setOrder('DESC');
            dispatch(resetPostSuccessful());
        }
        if (putSuccessful) {
            setActiveIndex(null);
            dispatch(resetPutSuccessful());
        }
    }, [dispatch, page, orderBy, order, trajectoryId, status, postSuccessful, putSuccessful]);

    useEffect(() => {
        dispatch(getAllTrajectoryAsync());
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    const getPatientHistoryRequest = () => {
        dispatch(cleanPatientHistory());
        dispatch(getPatientHistoryAsync({ page: pageHistory, query: queryHistory.toString() }));
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const headers = [
        {
            label: t('components.PatientTable.headers.lastName'),
            id: 'lastName',
            sortable: true,
        },
        {
            label: t('components.PatientTable.headers.firstName'),
            id: 'firstName',
            sortable: true,
        },
        {
            label: t('components.PatientTable.headers.fileNumber'),
            id: 'fileNumber',
            sortable: true,
        },
        {
            label: t('components.PatientTable.headers.nam'),
            id: 'nam',
            sortable: true,
        },
        {
            label: t('components.PatientTable.headers.trajectory'),
            id: 'trajectoryId',
            sortable: true,
        },
        {
            label: t('components.PatientTable.headers.status'),
            id: 'status',
            sortable: true,
        },
        {
            label: t('components.PatientTable.headers.patientDelai'),
            id: 'patientDelai',
            sortable: false,
        },
        {
            label: '',
            id: 'controls',
        },
    ];

    const headersHistory = [
        {
            label: t('components.PatientTable.headers.lastName'),
            id: 'lastName',
        },
        {
            label: t('components.PatientTable.headers.firstName'),
            id: 'firstName',
        },
        {
            label: t('components.PatientTable.headers.fileNumber'),
            id: 'fileNumber',
        },
        {
            label: t('components.PatientTable.headers.nam'),
            id: 'nam',
        },
        {
            label: t('components.Table.headers.lastModifiedBy'),
            id: 'lastModifiedBy',
        },
        {
            label: t('components.Table.headers.action'),
            id: 'action',
        },
        {
            label: t('components.Table.headers.lastModifiedDate'),
            id: 'lastModifiedDate',
            sortable: true,
        },
    ];

    const trajectoryIds = [
        {
            label: t('entity.parcours.selectTrajectory'),
            value: '',
            disabled: true,
        },
        ...trajectory.map((trajectoryId1) => ({
            label: trajectoryId1.name,
            value: trajectoryId1.id,
        })),
    ] as SelectOption[];

    const statusOption = [
        {
            label: t('components.PatientTable.status.ongoing'),
            value: 'ongoing',
        },
        {
            label: t('components.PatientTable.status.completed'),
            value: 'completed',
        },
        {
            label: t('components.PatientTable.status.cancelled'),
            value: 'cancelled',
        },
    ] as SelectOption[];

    const trajectoryOptions = [
        {
            label: t('entity.parcours.selectTrajectory'),
            value: '',
            disabled: true,
        },
        ...trajectory.map((trajectoryId1) => ({
            label: trajectoryId1.name,
            value: trajectoryId1.id,
        })),
    ] as SelectOption[];

    useEffect(() => {
        // Select the first trajectory if there is more than one at start
        // index 0 is the "Choisir un guichet" option
        if (trajectoryId && trajectoryId === -1 && trajectoryOptions.length > 1) {
            setTrajectoryId(Number(trajectoryOptions[1].value));
        }
    }, [trajectoryOptions]);

    const showHistoryHandler = (index: number) => {
        setQueryHistory(items[index].id);
        dispatch(cleanPatientHistory());
        dispatch(getPatientHistoryAsync({ page: pageHistory, query: String(items[index].id) }));
        openModal();
    };

    const removePatientHandler = (index: number) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce patient?')) {
            dispatch(deletePatientAsync({ patient: items[index], index }));
        }
    };

    const setActiveIndexHandler = (index: number) => {
        setPatientOngoing(items[index]);
        setActiveIndex(index);
    };

    const setExpandedRowIdHandler = (rowId: number = -1) => {
        if (expandedRowId === rowId) {
            setExpandedRowId(-1);
            dispatch(changePatient(-1));
        } else {
            setExpandedRowId(rowId);
            dispatch(changeQueryPatient(String(rowId)));
            dispatch(changeQueryStep(String(trajectoryId)));
            dispatch(changePatient(rowId));
            dispatch(changeToAmountStep(1));
        }
    };

    const userCanEditPatient =
        user && USER_ROLES_HAVING_THE_RIGHT_TO_EDIT_PATIENTS.includes(user.role);

    const userCanDeletePatient = user && user.role === 'admin';

    const readOnlyRow = (item: PatientType, index: number) => ({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        fileNumber: item.fileNumber,
        nam: item.nam,
        trajectoryId: item.trajectory?.name ?? '',
        status: t(`components.PatientTable.status.${statusFn(item)}`),
        patientDelai: item.patientDelai,
        controls: activeIndex ? (
            ''
        ) : (
            <>
                {userCanEditPatient && (
                    <TextButton onClick={() => setActiveIndexHandler(index)}>
                        {t('components.Table.edit')}
                        <IconSuffix icon={<Edit size="16" />} />
                    </TextButton>
                )}
                {userCanDeletePatient && (
                    <TextButton onClick={() => removePatientHandler(index)}>
                        {t('components.Table.remove')}
                        <IconSuffix icon={<RemoveIcon size="16" />} />
                    </TextButton>
                )}
                <TextButton onClick={() => showHistoryHandler(index)}>
                    {t('components.Table.history')}
                    <IconSuffix icon={<HistoryIcon size="16" />} />
                </TextButton>
                <TableExpandedContentToggler
                    onClick={() => {
                        setExpandedRowIdHandler(item?.id);
                    }}
                    label={t('components.Table.detail')}
                    expanded={expandedRowId === item?.id}
                />
            </>
        ),
        expandedContent:
            expandedRowId === item?.id ? (
                <TableExpandedContentContainer>
                    <PatientComment patient={item} index={index} />
                    <EntryTable />
                </TableExpandedContentContainer>
            ) : (
                <div />
            ),
    });

    const readOnlyHistoryRow = (item: PatientTypeHistory, _index: number) => ({
        ...item,
        lastModifiedBy:
            item.lastModifiedBy === 'system'
                ? t(`components.Table.modifiedBySystem`)
                : item.lastModifiedBy,
        action: t(`components.Table.actions.${action(item)}`),
    });

    const firstNameValidator = (value: string): boolean => value === '';

    const firstNameHandler = (value: string, _index: number) => {
        const patient = {
            ...patientOngoing,
            firstName: value.trim(),
        };
        firstNameErrorSetter(firstNameValidator(value.trim()));
        setPatientOngoing(patient);
    };

    const lastNameValidator = (value: string): boolean => value === '';

    const lastNameHandler = (value: string, _index: number) => {
        const patient = {
            ...patientOngoing,
            lastName: value.trim(),
        };
        lastNameErrorSetter(lastNameValidator(value.trim()));
        setPatientOngoing(patient);
    };

    const fileNumberValidator = (value: string): boolean => value === '';

    const fileNumberHandler = (value: string, _index: number) => {
        const patient = {
            ...patientOngoing,
            fileNumber: value,
        };
        fileNumberErrorSetter(fileNumberValidator(value));
        setPatientOngoing(patient);
    };

    const namValidator = (value: string): boolean =>
        !/^[A-Z]{4}\s?[0-9]{4}\s?[0-9]{4}$/.exec(value);

    const namHandler = (value: string, _index: number) => {
        const patient = {
            ...patientOngoing,
            nam: value.toUpperCase(),
        };
        namErrorSetter(namValidator(value.trim().toUpperCase()));
        setPatientOngoing(patient);
    };

    const trajectoryIdValidator = (value: number | string): boolean => value === '';

    const trajectoryHandler = (value: number, _index: number) => {
        const patient = {
            ...patientOngoing,
            trajectoryId: value,
        };
        trajectoryIdErrorSetter(trajectoryIdValidator(value));
        setPatientOngoing(patient);
    };

    const statusValidator = (value: string): boolean => value === '';

    const statusHandler = (value: string, _index: number) => {
        const patient = {
            ...patientOngoing,
            status: value,
        };
        statusErrorSetter(statusValidator(value));
        setPatientOngoing(patient);
    };

    const cancelAddHandler = (index: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler l'ajout?")) {
            dispatch(removePatient(index));
            setActiveIndex(null);
            dispatch(
                getPatientAsync({
                    page,
                    orderBy,
                    order,
                    trajectoryId,
                    status,
                    query: query !== '' ? query : undefined,
                }),
            );
        }
    };

    const cancelHandler = () => {
        if (window.confirm('Êtes-vous sûr de vouloir annuler la modification?')) {
            setActiveIndex(null);
        }
    };

    const validatePatient = () => {
        lastNameErrorSetter(lastNameValidator(patientOngoing.lastName || ''));
        firstNameErrorSetter(firstNameValidator(patientOngoing.firstName || ''));
        fileNumberErrorSetter(fileNumberValidator(patientOngoing.fileNumber));
        namErrorSetter(namValidator(patientOngoing.nam));
        trajectoryIdErrorSetter(trajectoryIdValidator(patientOngoing.trajectoryId || ''));
        statusErrorSetter(statusValidator(patientOngoing.status));
        return (
            lastNameValidator(patientOngoing.lastName) ||
            firstNameValidator(patientOngoing.firstName) ||
            fileNumberValidator(patientOngoing.fileNumber) ||
            namValidator(patientOngoing.nam) ||
            trajectoryIdValidator(patientOngoing.trajectoryId) ||
            statusValidator(patientOngoing.status)
        );
    };

    const putHandler = (index: number) => {
        if (validatePatient()) {
            return;
        }
        dispatch(putPatientAsync({ patient: patientOngoing, index }));
    };

    const postHandler = (index: number) => {
        if (validatePatient()) {
            return;
        }
        const patient = {
            ...patientOngoing,
            submissionDate: String(dayjs()),
        };
        dispatch(postPatientAsync({ patient, index }));
    };

    const editableRow = (item: PatientType, index: number) => ({
        id: item.id,
        lastName: (
            <TextField
                label={t('components.PatientTable.headers.lastName')}
                name="lastName"
                onChange={(e: React.FormEvent) =>
                    lastNameHandler((e.target as HTMLInputElement).value, index)
                }
                value={patientOngoing.lastName || ''}
                hasError={lastNameError}
                assistiveText={lastNameError ? 'Obligatoire' : ''}
                required
            />
        ),
        firstName: (
            <TextField
                label={t('components.PatientTable.headers.firstName')}
                name="firstName"
                onChange={(e: React.FormEvent) =>
                    firstNameHandler((e.target as HTMLInputElement).value, index)
                }
                value={patientOngoing.firstName || ''}
                hasError={firstNameError}
                assistiveText={firstNameError ? 'Obligatoire' : ''}
                required
            />
        ),
        fileNumber: (
            <TextField
                label={t('components.PatientTable.headers.fileNumber')}
                name="fileNumber"
                onChange={(e: React.FormEvent) =>
                    fileNumberHandler((e.target as HTMLInputElement).value, index)
                }
                value={patientOngoing.fileNumber || ''}
                hasError={fileNumberError}
                assistiveText={fileNumberError ? 'Obligatoire' : ''}
                required
            />
        ),
        nam: (
            <TextField
                label={t('components.PatientTable.headers.nam')}
                name="nam"
                onChange={(e: React.FormEvent) =>
                    namHandler((e.target as HTMLInputElement).value, index)
                }
                value={patientOngoing.nam || ''}
                hasError={namError}
                assistiveText={namError ? 'NAM doit avoir 4 lettres et 8 chiffres' : ''}
                required
            />
        ),
        trajectoryId: (
            <Select
                name="trajectoryId"
                options={trajectoryIds}
                label={t('components.PatientTable.headers.trajectoryId')}
                value={patientOngoing.trajectoryId || ''}
                onChange={(e: React.FormEvent) =>
                    trajectoryHandler(Number((e.target as HTMLInputElement).value), index)
                }
                hasError={trajectoryIdError}
                assistiveText={trajectoryIdError ? 'Obligatoire' : ''}
                variant="outlined"
            />
        ),
        status: (
            <Select
                name="status"
                options={statusOption}
                label={t('components.PatientTable.headers.status')}
                value={patientOngoing.status || ''}
                onChange={(e: React.FormEvent) =>
                    statusHandler((e.target as HTMLInputElement).value, index)
                }
                hasError={statusError}
                assistiveText={statusError ? 'Obligatoire' : ''}
                variant="outlined"
                required
            />
        ),
        patientDelai: '',
        controls:
            item.id === undefined ? (
                <>
                    <Button onClick={() => postHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelAddHandler(index)}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ) : (
                <>
                    <Button onClick={() => putHandler(index)}>{t('components.Table.save')}</Button>
                    <Button
                        style={{ marginLeft: '8px' }}
                        onClick={() => cancelHandler()}
                        variant="destructive"
                    >
                        {t('components.Table.cancel')}
                    </Button>
                </>
            ),
    });

    const preparedRows = (patients: PatientType[]) =>
        patients.map((item: PatientType, index: number) => {
            if (index === activeIndex) {
                return editableRow(item, index);
            }
            return readOnlyRow(item, index);
        });

    const preparedHistoryRows = (patientHistories: PatientTypeHistory[]) =>
        patientHistories.map((item: PatientTypeHistory, index: number) =>
            readOnlyHistoryRow(item, index),
        );

    const prev = () => {
        if (page > 0) {
            page = page - 1;
            dispatch(changeToAmount(page));
        }
    };

    const next = () => {
        if (page < totalPage) {
            page = page + 1;
            dispatch(changeToAmount(page));
        }
    };

    const prevHistory = () => {
        if (pageHistory > 0) {
            pageHistory = pageHistory - 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getPatientHistoryRequest();
    };

    const nextHistory = () => {
        if (pageHistory < totalPageHistory) {
            pageHistory = pageHistory + 1;
            dispatch(changeToAmountHistory(pageHistory));
        }
        getPatientHistoryRequest();
    };

    const pageNumberHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPage && newPageNumber > 0) {
            page = newPageNumber;
            dispatch(changeToAmount(page));
        }
    };

    const pageNumberHistoryHandler = (newPage: string) => {
        const newPageNumber = Number(newPage);
        if (newPageNumber <= totalPageHistory && newPageNumber > 0) {
            pageHistory = newPageNumber;
        }
        dispatch(changeToAmountHistory(newPageNumber));
        getPatientHistoryRequest();
    };

    const queryHandler = (value: string) => {
        dispatch(changeQuery(value));
    };

    const keyPress = (value: number) => {
        if (value === 13 && query !== '') {
            page = 1;
            dispatch(
                getPatientAsync({
                    page,
                    orderBy,
                    order,
                    trajectoryId,
                    status,
                    query: query !== '' ? query : undefined,
                }),
            );
        }
    };

    const searchQueryHandler = () => {
        if (query !== '') {
            page = 1;
            dispatch(
                getPatientAsync({
                    page,
                    orderBy,
                    order,
                    trajectoryId,
                    status,
                    query: query !== '' ? query : undefined,
                }),
            );
        }
    };

    const addMoreHandler = () => {
        lastNameErrorSetter(false);
        firstNameErrorSetter(false);
        fileNumberErrorSetter(false);
        namErrorSetter(false);
        trajectoryIdErrorSetter(false);
        statusErrorSetter(false);
        dispatch(addPatient());
        setPatientOngoing({ status: 'ongoing', comment: '' } as PatientType);
        setActiveIndex(0);
    };

    const cleanQueryHandler = () => {
        dispatch(changeQuery(''));
        page = 1;
        dispatch(changeToAmount(page));
        dispatch(
            getPatientAsync({
                page,
                orderBy,
                order,
                trajectoryId,
                status,
            }),
        );
    };

    const writePermission = (questionnedUser: UserType) =>
        questionnedUser.role === 'admin' ||
        questionnedUser.role === 'technicien' ||
        questionnedUser.role === 'manager';

    const orderHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderBy(by);
        setOrder(sortOrder);
        page = 1;
        dispatch(changeToAmount(page));
    };

    const orderHistoryHandler = (currentSortingOptions: SortOptions) => {
        const inactiveSortOrder = currentSortingOptions.sortOrder === 'INACTIVE';
        const by = inactiveSortOrder ? 'id' : currentSortingOptions.sortBy;
        const sortOrder = inactiveSortOrder ? 'ASC' : currentSortingOptions.sortOrder;
        setOrderByHistory(by);
        setOrderHistory(sortOrder);
        pageHistory = 0;
        dispatch(changeToAmount(pageHistory));
    };

    const updateTrajectoryChoices = (id: number) => {
        setTrajectoryId(id);
        pageNumberHandler('1');
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                <Title>{t('pages.Patient.history.title')}</Title>
                <TableRefiner>
                    <Table
                        headers={headersHistory}
                        rows={preparedHistoryRows(itemsHistory)}
                        sortOptions={{ sortBy: orderByHistory, sortOrder: orderHistory }}
                        onSort={(currentSortOptions) => orderHistoryHandler(currentSortOptions)}
                    />
                    {totalHistory > 10 && (
                        <Pagination>
                            <Prev onClick={prevHistory}>{t('components.Table.prev')}</Prev>
                            <InputPagination
                                value={pageHistory}
                                onChange={(e: React.FormEvent) =>
                                    pageNumberHistoryHandler((e.target as HTMLInputElement).value)
                                }
                            />{' '}
                            / {totalPageHistory}
                            <Next onClick={nextHistory}>{t('components.Table.next')}</Next>
                        </Pagination>
                    )}
                </TableRefiner>
            </Modal>
            <Title>
                {t('pages.Patient.title')}
                <ControlWrapper>
                    <>
                        <SelectWrapper>
                            <select
                                name="trajectoryId"
                                style={{ marginRight: '8px' }}
                                onBlur={(e: React.FormEvent) =>
                                    updateTrajectoryChoices(
                                        Number((e.target as HTMLInputElement).value),
                                    )
                                }
                                onChange={(e: React.FormEvent) =>
                                    updateTrajectoryChoices(
                                        Number((e.target as HTMLInputElement).value),
                                    )
                                }
                                value={trajectoryId}
                            >
                                {trajectoryOptions.map((item) => (
                                    <option
                                        key={item.value}
                                        value={item.value}
                                        disabled={item.disabled}
                                    >
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </SelectWrapper>
                        <SelectWrapper>
                            <select
                                name="status"
                                style={{ marginRight: '8px' }}
                                onBlur={(e: React.FormEvent) =>
                                    setStatus((e.target as HTMLInputElement).value)
                                }
                                onChange={(e: React.FormEvent) =>
                                    setStatus((e.target as HTMLInputElement).value)
                                }
                                value={status}
                            >
                                {statusOption.map((item) => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </SelectWrapper>
                        <QueryWrapper>
                            <QueryInput
                                value={query}
                                onChange={(e: React.FormEvent) =>
                                    queryHandler((e.target as HTMLInputElement).value)
                                }
                                onKeyDown={(e: React.KeyboardEvent) => keyPress(e.keyCode)}
                                placeholder="John Doe"
                            />
                            {query !== '' && (
                                <TimesCircleIcon
                                    size="16"
                                    onClick={cleanQueryHandler}
                                    style={{ marginRight: '8px' }}
                                />
                            )}
                            <SearchIcon size="16" onClick={searchQueryHandler} />
                        </QueryWrapper>
                        {user && writePermission(user) && (
                            <AddIcon size="16" onClick={addMoreHandler} />
                        )}
                    </>
                </ControlWrapper>
            </Title>
            <TableRefiner>
                <Table
                    headers={headers}
                    rows={preparedRows(items)}
                    sortOptions={{ sortBy: orderBy, sortOrder: order }}
                    onSort={(currentSortOptions) => orderHandler(currentSortOptions)}
                />
                {total > 10 && (
                    <Pagination>
                        {page > 1 && <Prev onClick={prev}>{t('components.Table.prev')}</Prev>}
                        <InputPagination
                            value={page}
                            type="number"
                            onChange={(e: React.FormEvent) =>
                                pageNumberHandler((e.target as HTMLInputElement).value)
                            }
                        />{' '}
                        / {totalPage}
                        {page < totalPage && (
                            <Next onClick={next}>{t('components.Table.next')}</Next>
                        )}
                    </Pagination>
                )}
            </TableRefiner>
        </>
    );
};
